* {
  padding: 0;
  margin: 0;
}
.tpSection {
  width: 90%;
  margin: auto;
  /* padding: 2rem 1rem; */
  /* background: rgb(245, 245, 249); */
  min-height: 65vh;
  position: relative;
  margin-bottom: 4rem;
  margin-top: 10rem;
}

.tpSection > h2 {
  text-align: center !important;
  font-weight: 900;
  font-size: 2.5rem;
}

.plansContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
}

/* .flexiContainer {
  width: 100%;
  margin-top: 2rem;
  
  display: flex;
  justify-content: center;
} */

.plan {
  position: relative;
  width: 100%;
  min-height: 40rem;
  /* padding-bottom: 2rem; */
  background: transparent;
  border-radius: 1.4rem;
  margin-bottom: 4rem;
}

.flexiPlan {
  width: 100%;
  padding-bottom: 2rem;
  background: white;
  border-radius: 1.4rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  margin-bottom: 4rem;
}

.flexiPlan:hover {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.plan:hover {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.planHeader {
  background: #e99e24;
  padding-top: 1.4rem;
  padding-bottom: 0.2rem;
  text-align: center;
  border-top-left-radius: 1.4rem;
  border-top-right-radius: 1.4rem;
}

.planHeader h4 {
  font-size: 1.4rem;
  color: #faf1d2;
}
.planPrice {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0;
}

.planPrice > p {
  color: #e99e24;
  padding: 0 !important;
  margin-top: 2rem;
}

.planPrice h2 {
  text-align: center;
  font-weight: 800;
  margin-top: 1rem;
}

.planBody {
}

.planPrice strong{
  /* color: #f4a92f; */
color: #fff;
  /* background: #1b1b20; */
  background: red;
  padding: 0.2rem 0.4rem;
  border-radius: 0.8rem;
  font-size: 14px;
}

.planBenefit {
  text-align: center;
  margin-bottom: 0.4rem;
  border-top: 1px solid #cdd5d9;
  border-bottom: 1px solid #cdd5d9;
}

.planBenefit p {
  margin-top: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.planBenefit span {
  font-size: 0.8rem;
  font-weight: 600;
}

.planBenefitcta {
}

.moreBenefitBtnContainer {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.moreBenefitBtnContainer button {
  width: 90%;
  padding: 0.5rem 0;
  border: 1px solid #e99e24;
  background: transparent;
  border-radius: 0.4rem;
  color: #e99e24;
  font-size: 1rem;
  font-weight: 800;
}

/* ================ flipper==================== */

#flipcheck {
  display: none;
}

.flipcontainer {
  perspective: 500px;
}

#flipcheck:checked ~ .flipcontainer {
  transform: rotateY(0deg);
}

#flipcheck:checked ~ .flipcontainer .flipper {
  transform: rotateY(-180deg);
}

.flipit {
  transform: rotateY(180deg);
}

.flipcontainer,
.front,
.back {
  width: 100%;
  height: 100%;
}

.flipper {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}

.frontbg {
  background: #ffffff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding-bottom: 2rem;
  border-radius: 1.4rem;
}
.backbg {
  padding-top: 1.5rem;
  border-radius: 1.4rem;
  background: #ffffff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding-bottom: 2rem;
}

/* =========================================== */

@media (min-width: 1080px) {
  .tpSection {
    width: 98%;
    padding: 2rem 1rem;
    margin-top: -4rem;
  }

  .tpSection h2 {
    text-align: center;
    font-size: 2.8rem;
  }

  .plansContainer {
    flex-direction: row;
  }

  .plan {
    width: 24%;
    background: transparent;
  }

  .flexiContainer {
    padding: 2rem;
  }
  .flexiPlan {
    width: 24%;
  }

  .planPrice h2 {
    text-align: center;
    font-weight: 800;
    font-size: 2rem;
  }
}
