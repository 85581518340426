@font-face {
  font-family: "CW BR Firma";
  src: url("../fonts/br-firma/BRFirmaCW-Regular.woff2") format("woff2");
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: "CW BR Firma";
  src: url("../fonts/br-firma/BRFirmaCW-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: "CW BR Firma";
  src: url("../fonts/br-firma/BRFirmaCW-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: "CW BR Firma";
  src: url("../fonts/br-firma/BRFirmaCW-Bold.woff2") format("woff2");
  font-weight: bold;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";
  src: url("../fonts/sailec/Sailec-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";
  src: url("../fonts/sailec/Sailec-Regular.woff2") format("woff2");
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
}
h3 {
  font-size: 2.9rem;
  font-weight: bold;
}

h4 {
  font-size: 1.9rem;
  font-weight: bold;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  outline: none !important;
  font-family: "CW BR Firma", sans-serif;
}

@media (min-width: 576px) {
  .uk-container {
    max-width: 540px;
  }
}
/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .uk-container {
    max-width: 720px;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .uk-container {
    max-width: 1081px;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .uk-container {
    max-width: 80%;
  }
}

.uk-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.uk-logo img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.uk-logo {
  height: 70%;
}

span.uk-position-relative {
  z-index: 900;
}
#topbar {
  z-index: 980 !important;
}
@media (min-width: 1111px) {
  #topbar {
    background: #ffffff;
    z-index: 999 !important;
  }
}

@media (max-width: 1110px) {
  #home {
    background: url(../img/respBG.png);
    background-size: 100% 100%;
    height: 170vh;
    padding-top: 100px;
  }

  .endTest #endorsements .uk-heading-primary {
    font-size: 3.2rem !important;
  }

  #topbar .uk-navbar-nav > li.uk-active > a,
  #topbar .uk-navbar-nav > li:hover > a {
    font-size: 1.6rem;
    text-transform: capitalize;
    animation: 0.5s forwards light-hover-active linear !important;
    animation-fill-mode: forwards;
  }

  .writeUP {
    padding: 15px;
  }

  .writeUP .uk-h1.title {
    font-size: 3.625rem;
    color: #ffffff !important;
  }

  .writeUP .uk-h4 {
    color: #ffffff !important;
  }

  .writeUP > div > div {
    padding: 0px !important;
  }

  .uk-logo {
    padding-left: 15px;
    width: 50vw;
  }
  .uk-sticky-placeholder {
    position: fixed !important;
    height: 0px !important;
    margin: 0px !important;
  }
  #topbar {
    background: linear-gradient(to right, #f9be69, #f99a47);
    width: 100vw !important;
  }
  .uk-navbar-nav {
    background: #ea9323;
  }
  .uk-navbar-nav > li > a {
    color: #ffffff !important;
  }
  #topbar .uk-navbar-nav > li > a:before {
    content: "";
    background: #ffffff !important;
    position: absolute;
    width: 100%;
  }
  .uk-navbar-nav > li.uk-active > a {
    color: #c6c6c6;
    font-weight: bold;
  }
}

#topbar nav.uk-navbar-container {
  background: transparent;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  /*height: 100px;*/
}

#topbar .uk-navbar-nav > li > a {
  text-transform: capitalize !important;
  font-size: 1rem;
  color: #7a7a7a;
  font-weight: bold;
}

#topbar .uk-navbar-nav > li.uk-active > a,
#topbar .uk-navbar-nav > li:hover > a {
  text-transform: none !important;
  font-size: 1rem;
  color: #7a7a7a;
  font-weight: bold;
  animation: 0.5s forwards hover-active linear;
  animation-fill-mode: forwards;
}

#topbar .uk-navbar-nav > li > a.uk-text-uppercase {
  text-transform: uppercase !important;
  font-weight: bolder !important;
  font-size: 16px;
}

.dark-bg-1 {
  background: #18191d !important;
}

.dark-bg-2 {
  background: #1c1f24 !important;
}

.silver-bg {
  background: silver !important;
}
.gold-bg {
  background: gold !important;
}

.platinum-bg {
  background: #e5e4e2 !important;
}

.single canvas {
  /*width: 100%!important;*/
}

.dark-bg-3 {
  background: #2a2b30 !important;
}

#topbar .uk-navbar-nav > li.uk-active > a.uk-text-uppercase,
#topbar .uk-navbar-nav > li:hover > a.uk-text-uppercase {
  text-transform: uppercase !important;
  font-weight: bolder !important;
  font-size: 16px !important;
}

.uk-slidenav polyline {
  stroke-width: 4px;
  color: #ea9e23;
}

@media (max-width: 767px) {
  .bissi > img {
    transform: translateX(100%) rotate(-45deg);
  }
  footer {
    overflow: hidden;
  }

  .bissi .talkto {
    left: 30vh;
  }
}

.uk-slidenav:hover polyline {
  stroke-width: 4px;
  color: #ea9e23;
}

header#home > div > ul.uk-slideshow-items {
  height: 80vh !important;
}

header#home > div > ul.uk-slideshow-items > li > div {
  height: 80% !important;
}

@media (max-width: 1110px) {
  header#home > div > ul.uk-slideshow-items {
    min-height: 160vh !important;
  }

  header#home > div > ul.uk-slideshow-items > li {
    position: relative !important;
    height: 100%;
  }

  header#home > div > ul.uk-slideshow-items > li > div {
    height: 30% !important;
  }

  .bissi > img {
    visibility: hidden;
  }
}

header#home > div > ul.uk-slideshow-items > li > div > img {
  object-fit: contain;
  object-position: center;
}

@media (min-width: 970px) {
  #home .uk-slidenav-previous {
    left: calc((100vw / 5) * 1.65);
    color: #ea9e23;
  }

  #home .uk-slidenav-next {
    right: -20px;
    color: #ea9e23;
  }
}
iframe {
  -webkit-mask-image: -webkit-radial-gradient(
    circle,
    white 100%,
    black 100%
  ); /*ios 7 border-radius-bug */
  -webkit-transform: rotate(
    0.000001deg
  ); /*mac os 10.6 safari 5 border-radius-bug */
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  overflow: hidden;
}

video.hideme {
  -webkit-mask-image: -webkit-radial-gradient(
    circle,
    white 100%,
    black 100%
  ); /*ios 7 border-radius-bug */
  -webkit-transform: rotate(
    0.000001deg
  ); /*mac os 10.6 safari 5 border-radius-bug */
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  overflow: hidden;
}

video {
  background: #000 !important;
}

.aboutText {
  top: -8px;
}

.aboutText .uk-heading-primary {
  color: #ea9e23;
  margin: 0;
}

.aboutText .uk-text-lead {
  font-size: 1rem;
  font-family: "CW BR Firma", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  color: #a6a6a6;
  margin-top: 0;
  line-height: 1.2;
}

.uk-link.prog-link,
.uk-link.prog-link:active,
.uk-link.prog-link:visited,
.uk-link.prog-link:hover {
  text-decoration: none;
  color: #ea9e23;
}

/* Nav link Animation*/

@keyframes hover-active {
  0% {
    color: #ea9e23;
  }
  80% {
    color: #ea9e23;
  }
  100% {
    color: #ea9e23;
  }
}

@keyframes light-hover-active {
  0% {
    color: #ffffff;
  }
  80% {
    color: #ea9e23;
  }
  100% {
    color: #9d9d9d;
  }
}

.uk-navbar-nav > li > a {
  color: #ea9e23;
}

#topbar .uk-navbar-nav > li > a:before {
  content: "";
  background: #ea9e23;
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 15px;
  padding-bottom: 5px;
  left: 0;
  border-radius: 5px;
  transform: scaleX(0);
  animation: 0.2s forwards no-hover-v linear;
  animation-fill-mode: forwards;
  z-index: -1;
}
#topbar .uk-navbar-nav > li > a:hover:before,
#topbar .uk-navbar-nav > li > a:focus:before,
#topbar .uk-navbar-nav > li.uk-active > a:before {
  /*animation: .5s forwards hover-v linear;*/
  /*animation-fill-mode: forwards;*/
}

@keyframes hover-v {
  0% {
    transform: scaleX(0);
    height: 1px;
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
    height: 2px;
  }
}
@keyframes no-hover-v {
  0% {
    transform: scaleX(1.05);
    height: 2px;
  }
  45% {
    height: 2px;
  }
  55% {
    transform: scaleX(1.05);
    height: 2px;
    opacity: 1;
  }

  100% {
    transform: scaleX(0);
    height: 2px;
    opacity: 0.02;
  }
}

@keyframes bissiThings {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Nav link Animation*/

/*button styling*/

.home-margin-top {
  margin-top: 10% !important;
}
.home-margin-top2 {
  margin-top: 15% !important;
}
.home-margin-top3 {
  margin-top: 5% !important;
}
.pln-btn {
  z-index: 99999 !important;
}

.return-btn {
  color: #ddd;
}
.return-btn:hover {
  color: #ea9e23;
}

.head-container {
  width: 100%;
  margin: -15px auto;
  margin-bottom: 5%;
}

.uk-slideshow-items {
  margin-top: -25px !important;
  min-height: 40vh !important;
}

.img-margin {
  margin-top: -45px !important;
}
/*!*Header Styling*!*/

.aboutHead:before {
  content: "";
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  padding-bottom: 2px;
  left: 0;
  border-radius: 5px;
}

#modal-full > .uk-modal-dialog {
  z-index: 999;
}

#modal-full p,
.uk-modal-full li {
  padding: 10px 0;
  font-size: 1.4rem;
}

#overviewCard {
  padding-bottom: 100px;
}

.uk-modal-full .uk-heading-primary {
  font-family: "CW BR Firma", sans-serif;
}

.uk-modal-full ul {
  list-style-type: none;
}

/*ABOUT*/
#about {
  /*padding-top: 100px;*/
  position: relative;
  /*min-height: 70vh;*/
  /*padding-top: 100px;*/
}

/*ABOUT*/

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "CW BR Firma", sans-serif;
}

.uk-h1 span {
  font-family: "CW BR Firma", sans-serif;
}

.uk-h1.title {
  font-size: 3.625rem;
  color: #ea9e23;
}

.uk-dotnav {
  justify-content: center;
  top: -70px;
}
.uk-dotnav > .uk-active > * {
  background-color: #ea9e23;
}

.uk-dotnav > * > * {
  border: 3px solid #ea9e23;
}

.uk-dotnav > * > :focus,
.uk-dotnav > * > :hover {
  background-color: #ea9e23;
  outline: 0;
  border-color: transparent;
}

/*How It Works*/
#howitworks,
#plans {
  padding-top: 100px;
  position: relative;
}
#contact {
  /*margin-top: 50px!important;*/
  position: relative;
  /*min-height: 150vh;*/
}
.site-footer {
  margin-top: 8% !important;
}

/*#faq>div{*/
/*position: relative;*/
/*min-height: 70vh;*/
/*}*/

#howitworks .uk-heading-primary {
  color: #ea9e23;
}

#howitworks .uk-card .uk-card-body img {
  width: 30%;
}

#howitworks .uk-card-media-top img {
  height: 120px;
}

#howitworks .uk-card .uk-card-title {
  color: #ea9e23;
  font-family: "CW BR Firma", sans-serif;
  margin-top: 10px;
}
.writeUP .uk-button.prog-button:before {
  content: "";
  left: 0;
  top: 0;
  background: #ffffff;
  transform: rotate(5deg);
  /*position: absolute;*/
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /*z-index: 0;*/
  transition: all 0.5s;
}
.writeUP .uk-button.prog-button:hover {
  color: #ffffff !important;
  background-color: #161616 !important;
}
.uk-button.prog-button {
  /*position: relative;*/
  background: #ea9e23;
  color: #ffffff;
  padding: 5px 20px;
  width: auto;
  text-transform: none;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "CW BR Firma", sans-serif;
  letter-spacing: 2px;
  border-radius: 5px;
  /*z-index: 1;*/
  transition: all 0.5s;
}
button,
.button {
  background-color: #ea9e23;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  min-height: 36px;
  color: white;
  line-height: 36px;
  padding-left: 1.2em;
  padding-right: 1.2em;
  position: relative;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;
  transition-duration: 300ms;
  font-family: "Sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  text-transform: capitalize;
  transition-property: background-color, opacity, color, transform, box-shadow;
  transition-timing-function: ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: background-color, opacity, color, transform, box-shadow;
  box-shadow: 0 2px 4px 0 rgba(2, 43, 105, 0.08);
}

.uk-button.prog-button:before {
  content: "";
  left: 0;
  top: 0;
  background: #ea9e23;
  transform: rotate(5deg);
  /*position: absolute;*/
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /*z-index: 0;*/
  transition: all 0.5s;
}

.writeUP .uk-button.prog-button {
  /*position: relative;*/
  background: #ffffff;
  color: #ea9e23;
  padding: 5px 30px;
  width: auto;
  text-transform: none;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "CW BR Firma", sans-serif;
  letter-spacing: 2px;
  border-radius: 5px;
  /*z-index: 1;*/
  transition: all 0.5s;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  z-index: 999999;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uk-navbar-item .uk-button.prog-button {
  font-size: 1rem;
  padding: 0 30px;
}

#home .bottom-title {
  margin: 0;
  font-family: "CW BR Firma", sans-serif;
  letter-spacing: 2px;
  font-size: 1.8rem;
  top: -55px;
}

#home .bottom-title .uk-text-bold {
  font-family: "CW BR Firma", sans-serif;
  font-size: 2rem;
  font-weight: bolder;
}

.uk-button.prog-button-outline:hover:before {
  content: "";
  left: 0;
  top: 0;
  background: #ea9e23;
  transform: rotate(-5deg);
  /*position: absolute;*/
  width: 100%;
  height: 100%;
  border-radius: 20px;
  /*z-index: 0;*/
  transition: all 0.5s;
}
.uk-button.prog-button-outline {
  /*position: relative;*/
  background: #ffffff;
  color: #ea9e23;
  padding: 10px 30px;
  width: auto;
  text-transform: none;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "CW BR Firma", sans-serif;
  letter-spacing: 2px;
  border-radius: 20px;
  /*z-index: 1;*/
  transition: all 0.5s;
  border: 2px solid #ea9e23;
}

.writeUP .uk-button.prog-button-outline {
  /*position: relative;*/
  background: transparent;
  color: #ffffff;
  padding: 10px 30px;
  width: auto;
  text-transform: none;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "CW BR Firma", sans-serif;
  letter-spacing: 2px;
  border-radius: 20px;
  /*z-index: 1;*/
  transition: all 0.5s;
  border: 2px solid #ffffff;
}
.uk-button.prog-button-outline:before {
  content: "";
  position: relative;
  transform: rotate(5deg);
}
.uk-button.prog-button-outline:hover {
  position: relative;
  background: #ea9e23;
  color: #ffffff;
}

.uk-button.prog-button:hover:before {
  transform: rotate(-5deg);
  background: #ea9e23;
}

#home .prog-button,
#home .prog-button-outline {
  font-size: 1.1rem;
  padding: 2px 30px;
  margin-top: 10px;
}

.aboutKey {
  font-size: 2rem !important;
}

#modal .uk-card {
  border-radius: 25px;
}

.bgyellow {
  background: yellow;
}

.bgred {
  background: red;
}

.bggreen {
  background: green;
}

#modal-full .uk-card {
  box-shadow: 0 0 15px;
  border-radius: 25px;
}

.bgpurple {
  background: purple;
}

.bgblue {
  background: deepskyblue;
}

.bgwhite {
  background: #ffffff;
}

#modal-full .uk-list > li {
  font-size: 1.1rem;
}
/*How It Works*/

/*Media Responsive Work*/
@media (min-width: 1111px) {
  #topbar .uk-navbar-left {
    width: 20%;
  }

  header .uk-width-2-5\@m {
    width: 46%;
  }
  header .uk-width-3-5\@m {
    width: 54%;
  }
}

@media (max-width: 1366px) {
}
@media (min-width: 768px) and (max-width: 1110px) {
  .bissi .talkto {
    height: 100%;
    width: 100%;
    transform: translateX(100%) !important;
  }
}
@media (max-width: 1110px) {
  #home .prog-button,
  #home .prog-button-outline {
    box-shadow: -2px 2px 5px;
  }

  footer {
    max-width: 98vw;
  }

  .footer .uk-container {
    width: 95%;
  }

  .site-footer img {
    width: 100%;
    object-fit: contain;
  }
  .uk-navbar-nav > li.uk-active > a {
    color: #333 !important;
  }

  .uk-navbar-item,
  .uk-navbar-nav > li > a,
  .uk-navbar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 80px;
    padding: 0 15px;
    font-size: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    text-decoration: none;
  }

  #topbar nav.uk-navbar-container {
    margin: 0 auto;
    height: auto !important;
  }

  .uk-navbar-left {
    height: 100px;
  }

  .uk-navbar-toggle {
    position: fixed;
    top: 50px;
    transform: translateY(-50%);
    right: 25px;
    width: 30px;
    padding: 0;
  }

  .uk-navbar-nav {
    flex-direction: column;
    top: 100px;
    width: 100%;
    position: fixed;
    background-color: #ffffff;
    left: 0;
  }
  .uk-navbar-right {
    margin-left: 0 !important;
  }
}
@media (max-width: 584px) {
}
@media (max-width: 427px) {
}
/*Media Responsive Work*/
/*FAQ*/
#faq .uk-accordion-title:after {
  content: none !important;
}

#faq .uk-heading-primary {
  color: #ea9e23;
}

#faq .uk-accordion-title {
  /*height: 50px;*/
  /*line-height: 50px;*/
  /*background-color: #F1F1F1;*/
  border-radius: 15px;
  padding-left: 20px;
}

#faq li {
  font-size: 1.2rem;
}

#faq .uk-accordion-content {
  padding-left: 20px;
}

/*#contact{
    background: url(../img/contact.png);
    -webkit-background-size: 120% 110%;
    background-size: 120% 110%;
}

#contact .uk-heading-primary, #contact .uk-text-lead{
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
}
#contact .uk-heading-primary{
    margin-bottom: 0;
}*/

.uk-input,
.uk-select,
.uk-textarea {
  background: transparent;
  border: 2px transparent solid;
  border-bottom: solid 1px black;
  box-shadow: none !important;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  border: 2px transparent solid;
  border-bottom: solid 2px #ea9e23;
  background: transparent;
}
.uk-form-label {
  color: black;
  font-size: 1.1rem;
  position: absolute;
  top: -15px;
  left: 0;
}

.uk-input:focus ~ label,
.uk-select:focus ~ label,
.uk-textarea:focus ~ label {
  color: #ea9e23 !important;
}

.theme-color {
  color: #ea9e23 !important;
}
.theme-bg {
  background: #ea9e23 !important;
}

#contact .uk-card-default {
  box-shadow: none;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
/*FAQ*/
/*Plans*/
#plans {
  width: 100%;
  min-height: 90vh;
  background: url(../img/trustplan.png) center top;
  background-size: 115% 115%;
}

#plans .uk-card {
  perspective: 150rem;
}

#plans .uk-card .back {
  height: auto;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  /*-webkit-box-shadow: 0 1.5rem 4rem rgba(0,0,0,0.15);*/
  /*box-shadow: 0 1.5rem 4rem rgba(0,0,0,0.15);*/
}

#plans .uk-card .front {
  height: 100%;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  background: #ffffff;
  overflow: hidden;
  /*-webkit-box-shadow: 0 1.5rem 4rem rgba(0,0,0,0.15);*/
  /*box-shadow: 0 1.5rem 4rem rgba(0,0,0,0.15);*/
}

#plans .backtxt p {
  margin: 5px 0;
  font-size: 1rem;
}

#plans .uk-card:hover .front {
  transform: rotateY(-180deg);
}

#plans .uk-card:hover .back {
  transform: rotateY(0);
}

#plans .uk-card .back {
  transform: rotateY(180deg);
}

#plans .silver {
  background-color: #e5e4e2;
  min-height: 80px;
}

#plans .gold {
  background-color: #ea9e23;
  min-height: 80px;
}

#plans .platinum {
  background-color: #c0c0c0;
  min-height: 80px;
}

#plans .value {
  color: darkslategrey;
  font-weight: 600;
  font-size: 14px;
}

#plans .cost {
  color: green;
  font-weight: 600;
  font-size: 14px;
}

.heade {
  font-family: "CW BR Firma", sans-serif;
}
#plans .heade {
  color: #ea9e23 !important;
}

.prog-button-back {
  margin-top: 10px;
  margin-bottom: 30px !important;
  font-size: 1.3rem;
}

#plans .uk-card .uk-card-body {
  padding: 15px;
  margin-bottom: 55px;
}
/*Plans*/
/*Testimonials*/

#testimonial .uk-heading-primary,
#endorsements .uk-heading-primary {
  color: #ea9e23;
}

/*html>body>main.main-content>#testimonial iframe.uk-responsive-width{
    height: 100%!important;
    width: 100%!important
}*/
#testimonial a img,
#endorsements a img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

img .endorsement-img {
  height: 293.95px !important;
  width: 305px !important;
  object-fit: cover;
}

@media (max-width: 556px) {
  img .endorsement-img {
    height: 293.95px !important;
    width: 305px !important;
    object-fit: cover;
    margin-left: 1rem !important;
  }
}

.endorsement {
  /*margin-left: 15px;*/
}
div .endorsement-img {
  -webkit-mask-image: -webkit-radial-gradient(
    /* circle, */ white 100%,
    black 100%
  ); /*ios 7 border-radius-bug */
  -webkit-transform: rotate(
    0.000001deg
  ); /*mac os 10.6 safari 5 border-radius-bug */
  /* -webkit-border-radius: 60px; */
  /* -moz-border-radius: 25px; */
  /*height: 293.95px!important;*/
  height: 350px !important;
  width: 400px !important;
  /* border: 4px solid red; */
}

#testimonial li > div,
#videos li > div {
  -webkit-mask-image: -webkit-radial-gradient(
    circle,
    white 100%,
    black 100%
  ); /*ios 7 border-radius-bug */
  -webkit-transform: rotate(
    0.000001deg
  ); /*mac os 10.6 safari 5 border-radius-bug */
  /* -webkit-border-radius: 25px; */
  /* -moz-border-radius: 25px; */
}

.theme-uline {
  border-bottom: 2px solid gold;
}

#videos .uk-overlay,
#testimonial .uk-overlay {
  padding: 15px;
}
.video-txt {
  color: white !important;
}

.slick-arrow {
  display: none !important;
}

/*Testimonials*/

/*Footer*/
.site-footer .footer {
  padding: 50px 0;
}
.site-footer .logo {
  height: 100px;
}

.footer .logo img {
  width: 100%;
  object-fit: contain;
}

.site-footer .ui.list a,
.site-footer .ui.list a:hover,
.site-footer .ui.list a:visited,
.ui.list p {
  text-decoration: none !important;
  font-size: 13px;
}

#testimonial {
  margin-bottom: 100px;
}

#endorsements {
  margin-bottom: 50px;
}

.site-footer .ui.list p {
  margin-bottom: 5px;
  margin-top: 5px;
}

.footer .item {
  font-family: "CW BR Firma", sans-serif;
  font-weight: bolder;
  font-size: 1rem !important;
}

.footer .item:hover {
  font-family: "CW BR Firma", sans-serif;
  font-weight: bolder;
  font-size: 1rem !important;
}
.footer .list > p {
  font-size: 1rem;
}

#secfooter {
  height: 50px;
  border-top: 2px solid #ea9e23;
}
.ui.vertical.segment {
  border-bottom: none !important;
}

.bissi > img {
  height: 400px;
  left: -40px;
}

.toggleImg {
  width: auto;
  height: 30px;
  object-fit: contain;
}

@media (min-width: 1111px) {
  .uk-hide\@m {
    display: none !important;
  }
}
@media (max-width: 1110px) {
  .uk-hide\@m {
    display: block !important;
  }
  .uk-vis\@m {
    display: none;
  }
}
#testimonials img,
#endorsements img {
  filter: dropShadow(20px);
}

#home {
  padding-top: 100px !important;
}

#topbar {
  width: 100%;
}

@media (max-width: 1110px) {
  .bissi > a {
    position: absolute;
    right: 0;
  }
}

.bissi > a {
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  height: 90px;
  width: 115px;
}
.bissi .talkto {
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
}

@media (min-width: 960px) {
  .bissi .talkto {
    height: 100%;
    width: 100%;
    transform: translateX(-10%);
  }
}

.light {
  font-weight: 100;
  font-family: "CW BR Firma", sans-serif;
}

ul.uk-nav.uk-nav-primary.uk-nav-center.uk-margin-auto-vertical {
  top: 7%;
}

#overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(249, 190, 105, 0.43),
    rgba(249, 154, 71, 0.42)
  );
  z-index: 12345678;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
}

#overlay.open {
  transform: translateX(0);
  transition: transform 0.4s ease-in-out;
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  box-sizing: border-box;
  width: 270px;
  z-index: 345678;
  padding: 20px 20px;
  background: #ea9323;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.top-right {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
}

.uk-nav-primary li a {
  color: #d3d3d3;
}

.uk-nav-primary li.uk-active a {
  color: #ffffff;
}

.endTest {
  padding-top: 150px;
}

.endTest #endorsements .uk-heading-primary {
  font-size: 5rem;
  display: inline-block;
  border-bottom: 1px solid grey;
  padding-bottom: 40px;
  margin-bottom: 10px;
}

.endTest .endorsements {
  padding: 40px;
}

.watch-more,
.watch-more:focus,
.watch-more:hover,
.watch-more:active,
.watch-more:visited {
  font-size: 2rem;
  text-decoration: none;
}

.slider-text {
  font-size: 55px !important;
}
.slider {
  margin-top: -10px !important;
}

#loan img {
  height: 65px;
}

#loan .uk-button.prog-button {
  font-size: 1.2rem;
}

#topbar .uk-button.prog-button {
  font-size: 0.8rem;
  padding: 5px 20px;
  min-height: 40px !important;
  height: 40px;
  margin-right: 10px;
  line-height: 30px;
  background: #ea9e23;
}

#topbar .uk-button.prog-button:before {
  content: "";
  height: 100%;
  width: 100%;
  background: #ea9e23;
}

.uk-button.prog-button:hover {
  /*animation: none;*/
  padding: 5px 20px;
  line-height: 30px !important;
  background-color: #161616 !important;
}

#loan h2 {
  word-spacing: 3px;
  letter-spacing: 5px;
}

.brounded {
  border-radius: 25px;
}

.u-modal {
  position: fixed; /* or absolute */
  height: auto;
  border-radius: 40px;
  padding: 2%;
}
.buy-button {
}

.buy-button:hover {
  color: #fff !important;
  background-color: #ea9e23;
}
/*Footer*/
.w20\% {
  width: 20%;
}

.lh38 {
  line-height: 42px;
  top: 7px;
  color: #ea9e23 !important;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none !important;
  padding-left: 10px;
  display: inline-block;
  margin-top: 10px;
}

#try p {
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 5px;
}
#try,
#endorsements,
#faq {
  padding: 100px 0;
  padding-bottom: 0;
}
.uk-accordion-title::before {
  content: "";
  position: inherit;
  transform: translateY(-50%);
  top: 50%;
}

.uk-text-center.uk-accordion.uk-grid-margin.uk-first-column {
  width: 80%;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .visiBissi {
    height: 20px;
    position: relative;
    top: calc((-100vh) + 40px);
  }

  .visiBissi.animateIt ~ #supportchatwidget #widget-open .logo-img-icon {
    display: none;
  }

  #supportchatwidget {
    animation: bissiThings forwards linear 0.5s;
  }

  .visiBissi.animateIt
    ~ #supportchatwidget
    .tc-widget.widget-fadeOut
    + #widget-open {
    height: 70vh !important;
    width: 250px !important;
    background-color: transparent !important;
    background: url(../img/bissi.png) no-repeat;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-size: contain;
    background-position: bottom right;
    animation: bissiThings forwards linear 0.5s;
  }

  /*.visiBissi.animateIt ~ #supportchatwidget .circular-widget{*/
  /*bottom: -30px;*/
  /*}*/
}

.hideme {
  width: 100% !important;
  height: 300px !important;
}
.hideme-endorse {
  width: 100% !important;
  height: 100% !important;
}

#anchor.uk-input {
  margin-top: -45px;
  margin-left: 10px;
}
html {
  overflow-x: hidden !important;
}
.etf-logo {
  width: 28% !important;
}
.form-input {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f4f4f7;
  color: #aaaaaa;
  font-weight: bold;
  height: 40px;
  font-size: 16px;
  border: none;
  margin-bottom: 10px;
}
.form-area {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f4f4f7;
  color: #aaaaaa;
  font-weight: bold;
  height: 150px;
  font-size: 16px;
  border: none;
  margin-bottom: 10px;
}
.form-input::placeholder {
  color: #aaaaaa !important;
}
.form-area::placeholder {
  color: #aaaaaa !important;
  /*padding-top: 10px!important;*/
}
.uk-input::placeholder {
  color: #aaaaaa !important;
  /*padding-top: 10px!important;*/
  font-size: 14px !important;
}
.react-phone-number-input__input::placeholder {
  color: #aaaaaa !important;
  /*padding-top: 10px!important;*/
  font-size: 14px !important;
}
.reg-text-red {
  color: red !important;
  margin-left: -1% !important;
  font-size: 18px !important;
}
.contact-form span {
  color: red !important;
  font-size: 12px !important;
}
.btn-form {
  margin-top: -2px !important;
  width: 100%;
  text-align: center;
}
.message-margin {
  margin-top: 40%;
}
.message-card {
  border: ridge 2px;
  border-color: #eae6e33d;
  padding-bottom: 50px;
  max-width: 70%;
}
.faq-container {
  margin-left: 10%;
  margin-right: 10%;
}
.faq-q {
  font-weight: bold;
  color: #ea9e23;
  font-size: 16px;
  /*background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");*/
  background-repeat: no-repeat;
  background-position: 0%;
  padding: 20px;
  background-color: none !important;
}
.faq-ans {
  font-size: 16px;
  line-height: 26px;
  color: #aaaaaa;
}
.faq-ans ol li {
  font-size: 16px;
  line-height: 26px;
  color: #aaaaaa;
}
@media screen and (max-width: 600px) {
  .tab-container .tab__item {
    font-size: 1.1rem;
  }
  .wad {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .app {
    /* display: none!important; */
    content: url(../img/app.png);
    height: 300px;
    width: 120px;
  }
  .hwt-width {
    width: 100% !important;
  }
  .perks-section {
    display: none;
  }
  .mob-align-center {
    text-align: center !important;
  }
  .message-margin {
    margin-top: 0%;
  }
  .videos-padding {
    padding-right: 10px;
    padding-left: -10px;
  }
  .uk-overlay {
    margin-right: 10px !important;
    margin-left: -10px !important;
  }
  .head-container {
    text-align: center;
    margin-top: 20% !important;
  }
  .slid-text {
    width: 100% !important;
    text-align: center;
  }
  .react-phone-number-input__input {
    margin-top: 10% !important;
  }
  .uk-width-2-4 {
    margin-top: -20% !important;
  }
  .full-width {
    width: 100% !important;
  }
  .txt-margin {
    margin-left: 0% !important;
  }
  h1 {
    font-size: 300% !important;
  }
  .text-left {
    margin-left: 0% !important;
  }
  .foot-img {
    display: none;
  }
  .contact-mobile {
    margin-top: 15%;
    width: 100% !important;
    text-align: center;
  }
  .message-card {
    max-width: 100%;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .footer-mobile {
    width: 100% !important;
    margin-left: 5% !important;
    margin-top: -5% !important;
  }
  .card-trst {
    width: 100% !important;
  }
  .aboutText {
    display: none !important;
  }
}

.wad {
  animation: revealWad cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.6s;
  margin-top: -12%;
}
.aye-logo {
  width: 30px;
  margin-bottom: -1px;
  margin-left: 2px;
}
.d-btn {
  margin-right: 20px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.d-btn:hover {
  background-color: #161616 !important;
}
.head-container {
  margin-top: 10%;
}
.home-etf-text {
  font-size: 250% !important;
}
.slid-note {
  font-weight: bold;
  color: #161616;
  font-size: 54.6px;
}
.txt-margin {
  margin-left: 8% !important;
}
.app-note {
  font-weight: bold;
  color: #161616;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 40px;
}
.app-container {
  margin-top: 20%;
}
.app-left {
  margin-left: 10%;
  margin-top: 10%;
}
.bg-link-water {
  background-color: #d2d2d230;
  width: 100% !important;
}
.container-how {
  margin-left: 10%;
  margin-right: 10%;
}
.container-perk {
  margin-top: 12%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: -20%;
}
.grid.center-xs {
  -ms-flex-pack: center;
  justify-content: center;
}
.grid.between-md {
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.how-n {
  color: #ea9e23;
  font-weight: bold;
  display: inline;
  font-size: 20x;
}
.how-t {
  color: #161616;
  font-weight: bold;
  display: inline;
  margin-left: 1%;
  text-transform: uppercase;
}
.how-c {
  color: #161616;
  margin-left: 5%;
}
.card-container {
  width: 80%;
  margin-left: 5%;
}
.card-margin {
  padding-top: 5%;
}
.tag-img {
  width: 50%;
  height: auto;
  right: -6%;
  margin-top: -6%;
  /*margin-bottom: -30px;*/
  position: absolute;
  /*z-index: 1;*/
}
div .tag-img.animated {
  animation-duration: 2.5s !important;
  animation-iteration-count: infinite !important;
}
div .text-flash.animated {
  animation-duration: 2.5s !important;
  animation-iteration-count: infinite !important;
}
img.animated {
  animation-duration: 2.5s !important;
  animation-iteration-count: infinite !important;
}
.pln-top-margin {
  margin-top: -3% !important;
}
.pln-img {
  width: 130px;
  height: auto;
  margin-bottom: -20px;
}
.pln-label {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-size: 0.7em !important;
}
.ln-height {
  display: block;
  /*margin-top: -10px;*/
}
.pln-data {
  display: inline;
  font-weight: bold;
  text-transform: uppercase;
  /*font-size: 18px;*/
  color: #ea9e23;
  width: 35%;
  float: right;
  font-size: 0.95em !important;
}
.plan-container {
  padding-left: 7%;
  padding-right: 5%;
}
.plan-container-trst {
  margin-left: 8%;
}
#trustplans .uk-grid {
  margin-left: 0px !important;
}
.remove-margin-top {
  margin-top: -5%;
}
.remove-margin-small-top {
  margin-top: -10%;
}
.lead-margin {
  margin-top: -25px !important;
}
.app {
  margin-top: -20%;
  content: url(../img/app.png);
  width: 300px;
}
.text-left {
  margin-left: 10%;
}

@media screen and (max-width: 768px) {
  .app {
    /* display: none!important; */
    content: url(../img/app.png);
    height: 300px;
    width: auto;
    margin-left: 65%;
  }
  .hwt-width {
    width: 100% !important;
  }
  .perks-section {
    display: none;
  }
  .mob-align-center {
    text-align: center !important;
  }
  .message-margin {
    margin-top: 0%;
  }
  .videos-padding {
    padding-right: 10px;
    padding-left: -10px;
  }
  .uk-overlay {
    margin-right: 10px !important;
    margin-left: -10px !important;
  }
}

.global-card {
  margin-top: 80px;
  /*background-image: url("../img/d-app2.svg");*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 360px;
}

.card-btn {
  color: #ffffff;
  background-color: #ffffff;
}

@media screen and (max-width: 600px) {
  .home-section {
    margin-top: -2px !important;
  }
  .global-card {
    background-position: -120px 400px;
    background-size: 920px 1420px;
  }
  .d-btn {
    display: inline-block;
    margin-top: 5px;
  }
  .message-margin {
    margin-top: -20% !important;
  }
  .global-card .footer__cta {
    min-height: 180px !important;
    /*margin-top: 10%!important;
        margin-bottom: 20%!important;*/
  }
  .hideme {
    width: 100% !important;
    height: 250px !important;
  }
}

.global-card .footer__cta {
  margin-left: 15% !important;
  width: 70%;
  border-radius: 12px;
  padding: 10px;
  background-color: #ea9e23;
  margin-top: 10%;
  margin-bottom: 20%;
  box-shadow: 0 59px 51px -34px rgba(247, 137, 0, 0.3);
  text-align: center;
}
.txt-white {
  color: white !important;
}
.try-btn {
  background-color: white !important;
  color: #ea9e23 !important;
}
.try-btn:hover {
  background-color: #161616 !important;
  color: #ffffff !important;
}

.global-card .footer__cta h4,
.global-card .footer__cta p {
  color: white;
}

.global-card .footer__cta h4 {
  margin-bottom: 0;
}

.global-card .footer__cta p {
  margin-top: 10px;
}

.global-card .footer__cta .footer-dl-badges {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
}

.global-card .footer__cta .footer-dl-badges svg {
  width: 190px;
  height: 60px;
}

@media screen and (max-width: 600px) {
  .global-card .footer__cta .footer-dl-badges svg {
    width: 160px;
    height: 50px;
  }
}

@media screen and (max-width: 400px) {
  .global-card .footer__cta .footer-dl-badges svg {
    width: 125px;
    height: 40px;
  }
}

.global-card .footer__menu {
  padding: 40px 35px;
}

@media screen and (max-width: 600px) {
  .global-card .footer__menu {
    padding: 0;
  }
}

.global-card .footer__menu .footer__menu-header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.global-card .footer__menu ul li {
  list-style: none;
  margin-bottom: 15px;
}

.global-card .footer__menu ul li a {
  font-weight: normal;
  opacity: 0.7;
  transition: opacity 300ms ease;
}

.global-card .footer__menu ul li a:hover {
  opacity: 1;
}

.global-card .footer__address {
  margin-top: 40px;
  margin-bottom: 40px;
}

.global-card .footer__address p {
  font-family: "CW BR Firma", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.55rem;
}

.global-card .footer__social {
  display: inline;
}

.global-card .footer__social svg {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.global-card .footer__legal {
  font-size: 1.3rem;
  font-family: "CW BR Firma", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  opacity: 0.5;
}

.perks-section {
  position: relative;
  margin-bottom: 200px;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .perks-section {
    margin-bottom: 130px;
  }
}

.perks-section h6 {
  letter-spacing: 1px;
  font-size: 1.6rem;
  margin-bottom: 30px;
}

.perks-section .perk {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media screen and (max-width: 600px) {
  .perks-section .perk {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.perks-section .perk .perk__question {
  font-weight: bold;
  font-size: 6rem;
  opacity: 0;
  margin-right: 15px;
  will-change: opacity, tranform;
}

@media screen and (max-width: 768px) {
  .perks-section .perk .perk__question {
    font-size: 4rem;
  }
}

.perks-section .perk .perk__link {
  color: #d87640;
}

.perks-section .perk .perk__answer {
  font-weight: bold;
  font-size: 6rem;
  margin-left: 24px;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .perks-section .perk .perk__answer {
    margin-left: 0;
    font-size: 4rem;
  }
}

@keyframes slideOutQuestion {
  0% {
    opacity: 0.4;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }
}

@keyframes slideOutAnswer {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }
}

.slide-out-question {
  animation-name: slideOutQuestion;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

.slide-out-answer {
  animation-name: slideOutAnswer;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

@keyframes slideInQuestion {
  0% {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }

  100% {
    opacity: 0.5;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInAnswer {
  0% {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slide-in-question {
  animation-name: slideInQuestion;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

.slide-in-answer {
  animation-name: slideInAnswer;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
  animation-timing-function: ease;
}

.global-nav {
  font-size: 1.5rem;
  height: 90px;
  position: absolute;
  right: 3%;
  width: 100%;
  left: 0;
  top: 0;
  /*z-index: 10000*/
}

@keyframes slideInDown {
  0% {
    transform: translate3d(0, -80px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

/*.global-nav--sticky {
    position: fixed;
    background-color: none;
    height: 80px;
    top: 0;
    box-shadow: 0 0px 22px rgba(0, 0, 0, 0.1);
    animation: slideInDown 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
}*/

@media screen and (max-width: 768px) {
  .global-nav--sticky {
    background-color: #ea9e23;
  }

  .global-nav--sticky .global-mobile-nav__trigger span {
    background-color: white;
  }

  .global-nav--sticky .global-mobile-nav__trigger span:before {
    background-color: white;
  }

  .global-nav--sticky .global-mobile-nav__trigger span:after {
    background-color: white;
  }

  .global-nav--sticky a.etf-logo {
    display: none;
  }

  .global-nav--sticky a.etf-logo.light {
    display: block !important;
  }
}

.global-nav__row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 75px;
}

.global-nav__row a.etf-logo {
  margin-top: 1%;
  margin-left: 5%;
  content: url(../img/nav.svg);
}

.global-nav__row a.etf-logo svg {
  height: 30px;
  width: 159px;
}

.global-nav__row a.etf-logo.light {
  display: none;
}

.global-nav .global-nav__menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .global-nav .global-nav__menu {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .global-nav__row a.etf-logo {
    margin-top: 1%;
    margin-left: 5%;
    content: url(../img/nav_next.svg);
  }
}

.global-nav .global-nav__menu ul.global-nav__list {
  list-style-type: none;
  display: flex;
  /*margin-left: 25%;*/
}
@media screen and (min-width: 1240px) {
  .global-nav .global-nav__menu ul.global-nav__list {
    margin-left: 25%;
  }
}

@media screen and (max-width: 768px) {
  .global-nav .global-nav__menu ul.global-nav__list {
    display: none;
  }
}

.global-nav .global-nav__menu ul.global-nav__list.light li a {
  color: white;
}

.global-nav .global-nav__menu ul.global-nav__list li a {
  margin-left: 40px;
  color: #161616;
  font-size: 90%;
  text-transform: capitalize;
  font-family: "Sailec", sans-serif !important;
  font-weight: 500;
  opacity: 0.6;
  transition: opacity ease 300ms;
  will-change: opacity;
}

.global-nav .global-nav__menu ul.global-nav__list li a.active {
  color: #ea9e23;
  font-weight: 500;
  opacity: 1;
}

.global-nav .global-nav__menu ul.global-nav__list li a:hover {
  color: #ea9e23;
  opacity: 1;
}

.global-nav .global-nav__menu .global-nav__ctas {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 980px) {
  .global-nav .global-nav__menu .global-nav__ctas a {
    display: none;
  }
}

.global-nav .global-nav__menu .global-nav__ctas > :first-child {
  margin-right: 20px;
}

.global-mobile-nav {
  display: none;
}

@media screen and (max-width: 768px) {
  .global-mobile-nav {
    display: block;
  }
}

.global-mobile-nav__trigger {
  cursor: pointer;
  display: inline-block;
  position: inherit;
  margin-top: 4px;
  height: 24px;
  width: 24px;
  margin-left: 15px;
}

.global-mobile-nav__trigger.light span {
  background-color: white;
}

.global-mobile-nav__trigger.light span:before,
.global-mobile-nav__trigger.light span:after {
  background-color: white;
}

.global-mobile-nav__trigger.is-active span {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.global-mobile-nav__trigger.is-active span:before {
  opacity: 0;
  transform: rotate(45deg) translate3d(-5.71429px, -6px, 0);
}

.global-mobile-nav__trigger.is-active span:after {
  transform: translate3d(0, -18px, 0) rotate(90deg);
}

.global-mobile-nav span {
  background-color: #161616;
  display: block;
  position: absolute;
  top: 3px;
  border-radius: 2px;
  transition: transform 0.3s ease;
  width: 25px;
  height: 3px;
}

.global-mobile-nav span:before,
.global-mobile-nav span:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 3px;
  border-radius: 2px;
  transition: transform 0.3s ease;
  background-color: #161616;
}

.global-mobile-nav span:before {
  top: 9px;
}

.global-mobile-nav span:after {
  top: 18px;
}

.global-mobile-nav__menu {
  display: none;
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  pointer-events: none;
  opacity: 0;
  background-color: #ea9e23;
  left: 0;
}

@media screen and (max-width: 768px) {
  .global-mobile-nav__menu {
    display: block;
  }
}

@keyframes slideInMenu {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes slideInMenuItem {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.global-mobile-nav__menu.is-active {
  animation: slideInMenu 0.6s cubic-bezier(1, 0, 0, 1) forwards;
  opacity: 1;
  pointer-events: all;
}

.global-mobile-nav__menu ul {
  padding: 10vh 35px 0 35px;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.global-mobile-nav__menu ul.small-menu {
  padding: 10px 35px 0 35px;
  opacity: 0;
}

.global-mobile-nav__menu ul.small-menu.is-active {
  animation: slideInMenuItem 0.6s ease forwards;
}

.global-mobile-nav__menu ul.small-menu li {
  margin-bottom: 0px;
  margin-top: 12px;
  padding: 10px 0;
}

.global-mobile-nav__menu ul.small-menu li a {
  font-size: 1.9rem;
  opacity: 0.8;
  line-height: 0.7;
  font-weight: 500;
}

.global-mobile-nav__menu ul.small-menu li a svg {
  vertical-align: middle;
  margin-left: 6px;
}

.global-mobile-nav__menu ul li {
  list-style: none;
  opacity: 0;
  margin-bottom: 15px;
}

.global-mobile-nav__menu ul li.is-active {
  animation: slideInMenuItem 0.6s ease forwards;
}

.global-mobile-nav__menu ul li a {
  color: white;
  display: block;
  font-weight: bold;
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
  transition: opacity 0.3s ease;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.06);
}

.global-mobile-nav__menu ul li a:hover {
  opacity: 0.8;
}

.global-mobile-nav__menu ul li span {
  font-size: 0.99rem;
  color: white;
  opacity: 0.7;
  margin-top: 5px;
  display: block;
}
.mobile-nav-icon {
  margin-top: -50%;
}
.etf-icon {
  margin-top: 10%;
}

.mobile-menu {
  background-color: #ea9e23;
  width: 100% !important;
  animation: slideInMenu 0.6s cubic-bezier(1, 0, 0, 1) forwards;
}

.mobile-menu .main-mobile-menu {
  margin-top: 30%;
}

.mobile-menu ul li {
  list-style: none;
}

.mobile-menu ul li .menu-txt-main {
  font-size: 250% !important;
  font-weight: bolder;
}

.mobile-menu ul li {
  margin-top: 5%;
}

.mobile-menu ul li:hover {
  color: #000 !important;
}

.mobile-menu ul li .menu-txt {
  font-size: 120% !important;
}

.mobile-nav-is-active {
  overflow: hidden;
}

.photo {
  height: 550px;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .photo {
    height: 400px;
    box-shadow: none;
  }
}

.photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.card {
  background: white;
  box-shadow: 0 26px 30px -14px rgba(247, 137, 0, 0.3);
  border-radius: 12px;
  padding: 20px 30px;
  margin: 60px 20px 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
  .card {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin: 20px 0;
  }
}

.card h5 {
  font-size: 2.7rem;
}

@media (max-width: 768px) {
  .card h5 {
    font-size: 2.2rem;
  }
}

.card a {
  margin-top: 80px;
}

@media (max-width: 768px) {
  .card a {
    margin-top: 20px;
  }
}

.card p {
  font-size: 16px;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 768px) {
  .card p {
    font-size: 15px;
  }
}

.global-card {
  margin-top: 80px;
  /*background-image: url("../img/d-app2.svg");*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 360px;
}

.card-btn {
  color: #ffffff;
  background-color: #ffffff;
}

@media screen and (max-width: 600px) {
  .home-section {
    margin-top: -2px !important;
  }
  .global-card {
    background-position: -120px 400px;
    background-size: 920px 1420px;
  }
  .d-btn {
    display: inline-block;
    margin-top: 5px;
  }
  .message-margin {
    margin-top: -20% !important;
  }
  .global-card .footer__cta {
    /*height: 150px!important;*/
  }
  #topbar {
    height: 80px;
    background: #ea9e23 !important;
    /*position: fixed!important;*/
  }
}

.global-card .footer__cta {
  border-radius: 12px;
  /*height: 270px;*/
  background-color: #ea9e23;
  /*margin-bottom: 40px;*/
  box-shadow: 0 59px 51px -34px rgba(247, 137, 0, 0.3);
}

.global-card .footer__cta h4,
.global-card .footer__cta p {
  color: white;
}

.global-card .footer__cta h4 {
  margin-bottom: 0;
}

.global-card .footer__cta p {
  margin-top: 10px;
}

.global-card .footer__cta .footer-dl-badges {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
}

.global-card .footer__cta .footer-dl-badges svg {
  width: 190px;
  height: 60px;
}

@media screen and (max-width: 600px) {
  .global-card .footer__cta .footer-dl-badges svg {
    width: 160px;
    height: 50px;
  }
}

@media screen and (max-width: 400px) {
  .global-card .footer__cta .footer-dl-badges svg {
    width: 125px;
    height: 40px;
  }
}

.global-card .footer__menu {
  padding: 40px 35px;
}

@media screen and (max-width: 600px) {
  .global-card .footer__menu {
    padding: 0;
  }
}

.global-card .footer__menu .footer__menu-header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.global-card .footer__menu ul li {
  list-style: none;
  margin-bottom: 15px;
}

.global-card .footer__menu ul li a {
  font-weight: normal;
  opacity: 0.7;
  transition: opacity 300ms ease;
}

.global-card .footer__menu ul li a:hover {
  opacity: 1;
}

.global-card .footer__address {
  margin-top: 40px;
  margin-bottom: 40px;
}

.global-card .footer__address p {
  font-family: "CW BR Firma", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.55rem;
}

.global-card .footer__social {
  display: inline;
}

.global-card .footer__social svg {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.global-card .footer__legal {
  font-size: 1.3rem;
  font-family: "CW BR Firma", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  opacity: 0.5;
}
.plan-card {
  background-size: 70%;
  border: ridge 2px;
  border-color: #ff650f52;
}
.plan-card-trst {
  background-size: 70%;
  margin: 10px !important;
  border: ridge 2px;
  border-color: #ff650f52;
}
.pln-select-card {
  /*display: inline-table;*/
  /*display: flex;*/
}
/*div.ReactModal__Overlay--after-open{
    background: #ffffffad!important;
    z-index: 99999!important;
}*/
/*Modal Background*/
div.ReactModal__Overlay--after-open {
  background: #000000ad !important;
  z-index: 99999 !important;
}
.plan-select.plan-switch li .card-trst .pln-name {
  font-weight: bold !important;
  font-size: 50% !important;
  margin-top: 30%;
  margin-bottom: 0%;
}

.plan-select.plan-switch li .card-trst {
  width: 60% !important;
  border-radius: 15px;
  border: none;
  box-shadow: 0 36px 46px -34px rgba(10, 5, 0, 0.2980392156862745);
}

.plan-select.plan-switch li .card-trst:hover img.hover-plan {
  display: none;
}

.plan-select li {
  display: inline-grid;
  margin-top: -20% !important;
  z-index: 99;
}
.plan-select li .card-trst {
  width: 90% !important;
  border-radius: 25px;
  border: none;
  box-shadow: 0 36px 46px -34px rgba(10, 5, 0, 0.2980392156862745);
}

.plan-select li .card-trst:hover {
  /*box-shadow: 0 26px 30px -14px rgba(247, 137, 0, 0.3);*/
  border: ridge 2px;
  border-color: #ff650f52;
}
img.hover-plan {
  /*opacity: 0;*/
  display: none;
  width: 20%;
  align-items: right !important;
  transition: 0.5s ease;
  margin-top: -10%;
}
.plan-select li .card-trst:hover img.hover-plan {
  transition: 0.5s ease;
  /*opacity: 1;*/
  display: initial;
  width: 20%;
}

img.hover-plan-select {
  transition: 0.5s ease;
  /*opacity: 1;*/
  display: initial;
  width: 20%;
  margin-left: 70% !important;
}

.plan-select-width {
  /*margin-left: 5%!important;*/
  width: 100%;
  display: inline-flex !important;
}

.plan-select-left {
  width: 20%;
}

.plan-select-right {
  width: 70%;
  margin-left: 5%;
  margin-top: 5%;
}

.plan-select-left .pln-selected-card {
  box-shadow: 0 16px 50px -5px rgba(10, 5, 0, 0.2980392156862745) !important;
  left: 15%;
}

.plan-select li .card-trst.pln-selected-card {
  width: 60% !important;
}

.plan-select li .card-trst .pln-name {
  font-weight: bold !important;
  font-size: 80% !important;
  margin-top: 30%;
  margin-bottom: 0%;
}

.plan-select li .card-trst:hover .pln-name {
  transform: translateY(-27%);
  transition: 0.3s ease;
}

.card-trst img.hover-plan {
  margin-left: 70% !important;
}

.plan-select .uk-accordion-title::before {
  display: none;
}

.trst-plan-bg {
  margin-top: -20%;
  /* height: 35vh; */
  height: 300px;
  background-color: #ea9e23;
}
.trst-plan-select-bg {
  margin-top: -15%;
  height: 300px;
  background-color: #ea9e23;
}

.plan-select li .card-trst .pln-img {
  /*width: 50%;*/
}

.plan-switch {
  margin-top: -10%;
  float: right;
  margin-right: -2%;
}

.plan-switch li a {
  /*margin-right: -60%!important;*/
  margin: 1%;
}

.other-margin {
  margin-top: -1%;
  margin-left: 40%;
}

.close-icon {
  width: 30px;
  margin: 10px;
  margin-bottom: 20px;
  float: right;
}

p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  /*margin: 0 0 0 -15px;*/
}

.choose-btn {
  display: initial;
  /*position: absolute;*/
  /*bottom: 0!important;*/
  /*margin-left: -8%;*/
  /*width: 100%;*/
}
.card-trst {
  background: white;
  border-radius: 12px;
  margin: 60px 10px 60px;
  width: 23%;
  /* 18 for 5 plans */
  /* width: 18%;  */
  padding: 10px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background-repeat: no-repeat;
}
.pln-name {
  text-transform: capitalize;
  font-size: 90%;
  font-weight: bold;
}
.foot-header {
  font-weight: bold;
  text-transform: uppercase;
  color: #615e5e;
}
.foot-item {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #615e5e;
}
.foot-item:hover {
  color: #ea9e23;
}
.foot-img {
  width: 80% !important;
  margin-top: 25%;
  margin-left: 10%;
}
.float {
  z-index: 99999 !important;
  bottom: 2%;
  right: 2%;
}
.etf-section {
  margin-top: -10%;
}
.vid {
  text-align: center;
}
.menu-txt {
  font-size: 80% !important;
}
.menu-txt-main {
  font-size: 140% !important;
}
.react-phone-number-input__input,
.react-phone-number-input__phone {
  margin-top: 2%;
}
.uk-text-large {
  font-size: 150% !important;
  margin-bottom: 10%;
}
.container-main {
  margin: 0 auto;
  max-width: 1310px;
  /*padding: 0 20px;*/
  width: 100%;
}

.perk span {
  margin-top: -20% !important;
  font-size: 300% !important;
}

.perks-section {
  position: relative;
  margin-bottom: 200px;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .perks-section {
    margin-bottom: 130px;
  }
}

.perks-section h6 {
  letter-spacing: 1px;
  font-size: 1.6rem;
  margin-bottom: 30px;
}

.perks-section .perk {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media screen and (max-width: 600px) {
  .perks-section .perk {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.perks-section .perk .perk__question {
  font-weight: bold;
  font-size: 6rem;
  opacity: 0;
  margin-right: 15px;
  will-change: opacity, tranform;
}

@media screen and (max-width: 768px) {
  .perks-section .perk .perk__question {
    font-size: 4rem;
  }
}

.perks-section .perk .perk__link {
  color: #ea9e23;
}

.perks-section .perk .perk__answer {
  font-weight: bold;
  color: #ea9e23;
  font-size: 6rem;
  margin-left: 24px;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .perks-section .perk .perk__answer {
    margin-left: 0;
    font-size: 4rem;
  }
}
.uk-width-2-4 {
  margin-top: -9%;
  background-color: none !important;
  text-align: justify !important;
}
.full-width {
  background: none !important;
}
.react-cookie-banner {
  background-color: #ea9e23 !important;
  position: fixed !important;
  bottom: 5px !important;
}
.button-close {
  margin-top: -1.3% !important;
}
.cookie-message {
  font-size: 80% !important;
}
.foot-adr {
  font-size: 90% !important;
}
.mb-top-margin {
  margin-top: -10% !important;
}
.offline {
}
input.uk-input {
  background: none !important;
}
.container-how .uk-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.perk-text-1 {
  letter-spacing: 1px;
  font-size: 2.9rem;
  font-weight: bold;
}
.perk-text-2 {
  letter-spacing: 1px;
  font-size: 2.9rem;
  font-weight: bold;
  margin-left: 2%;
  color: #ea9323;
}
.main-mobile-menu a.uk-offcanvas-close.uk-close.uk-icon {
  /*display: none;*/
  color: #ea9323;
  opacity: 0.1;
}
.modal-text {
  font-size: 12px;
}

@media screen and (max-width: 980px) {
  div.plan-select.plan-select-view ul {
    margin-top: 10% !important;
  }
  .other-margin {
    margin-top: -1%;
    margin-left: 0%;
  }
}
@media screen and (max-width: 600px) {
  .trst-plan-bg {
    display: none;
  }
  .trst-plan-select-bg {
    /* display: none; */
    height: 68vh;
  }
  .plan-select-width {
    /* width: 100%; */
    display: block !important;
    /* overflow-x: hidden!important; */
  }
  .plan-select-left {
    margin-top: 25%;
    width: 100% !important;
    display: block !important;
    margin-bottom: 10%;
  }
  .plan-select-right {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
    /* display: block!important; */
  }
  .uk-column-1-2 p li {
    width: 100%;
    /* display: block!important; */
  }
  .uk-column-1-2 {
    width: 100%;
    column-count: 0;
    display: block !important;
    margin-top: 10%;
    margin-bottom: 20%;
  }
}
