.codebreedslider {
  height: 250px;
  margin: 8rem auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.codebreedslide-track {
  display: flex;
  width: calc(250px * 18);
  animation: scroll 20s linear infinite;
}
.codebreedslide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}

.codebreedslide {
  height: 200px;
  width: 360px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.codebreedslide img {
  width: 100%;
  transition: transform 1s;
}

.codebreedslide img:hover {
  transform: translateZ(20px);
}
