@font-face {
    font-family: 'CW BR Firma';
    src: url("../fonts/br-firma/BRFirmaCW-Regular.woff2") format("woff2");
    font-weight: normal;
    font-display: fallback;
    font-style: normal
}

@font-face {
    font-family: 'CW BR Firma';
    src: url("../fonts/br-firma/BRFirmaCW-Medium.woff2") format("woff2");;
    font-weight: 500;
    font-display: fallback;
    font-style: normal
}

@font-face {
    font-family: 'CW BR Firma';
    src: url("../fonts/br-firma/BRFirmaCW-SemiBold.woff2") format("woff2");;
    font-weight: 600;
    font-display: fallback;
    font-style: normal
}

@font-face {
    font-family: 'CW BR Firma';
    src: url("../fonts/br-firma/BRFirmaCW-Bold.woff2") format("woff2");;
    font-weight: bold;
    font-display: fallback;
    font-style: normal
}

@font-face {
    font-family: 'Sailec';
    src: url("../fonts/sailec/Sailec-Medium.woff2") format("woff2");;
    font-weight: 500;
    font-display: fallback;
    font-style: normal
}

@font-face {
    font-family: 'Sailec';
    src: url("../fonts/sailec/Sailec-Regular.woff2") format("woff2");;
    font-weight: normal;
    font-display: fallback;
    font-style: normal
}

/** {*/
/*padding: 0;*/
/*margin: 0;*/
/*box-sizing: border-box;*/
/*position: relative;*/
/*outline: none!important;*/
/*font-family: 'CW BR Firma', sans-serif;*/
/*}*/
* {
    padding: 0;
    margin: 0;
    /*box-sizing: border-box;*/
    position: relative;
    outline: none!important;
    font-family: 'CW BR Firma', sans-serif;
}

/*SOLVED OVERSCROLLING ISSUE*/
html {
    /* overflow: hidden!important; */
    height: 100%!important;
}

body {
    height: 100%!important;
    overflow: unset!important;
}

#dash-content p, #dash-content h1,#dash-content h2,#dash-content h3,#dash-content h4,#dash-content h5,#dash-content h6,#dash-content span,#dash-content div,#dash-content section,#dash-content header{
    font-family: 'CW BR Firma', sans-serif;
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, h1, h2, h3, h4, h5, h6{
    font-family: 'CW BR Firma', sans-serif;
}

.uk-height-large{
    min-height: 450px;
    height: auto!important;
}

.uk-offcanvas-content .uk-form-label{
    left: 0;
}

.uk-form-label, .uk-form-controls, .uk-input{
    font-family: 'CW BR Firma', sans-serif;
}

@keyframes App-logo-spin {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
}
/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .uk-container {
        max-width: 80%;
    }
    .content-wrapper {
      margin-left: 200px;
    }
}

/*top spacing for dashboard contents on mobile*/
@media (max-width: 960px) {
    #dash-content{
        margin-top: 80px!important;
    }
    #dash-content #left{
        margin-top: 10%;
        width: 100%;
    }
    #right{
        display: none;
    }
    @-moz-document url-prefix(){
        .t-margin{
            margin-top: 30%!important; /*This fixed the wrapping on Trust Earnings for mozilla*/
        }
    }
    .chat-type-width{
        width: 70vw!important;
    }
    .dash-chat-margin{
        margin-top: 15%!important;
    }
}

.uk-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.uk-logo img{
    height: 100%;
    width: auto;
    object-fit: contain;
}

.uk-logo{
    height: 70%;
}

span.uk-position-relative{
    z-index: 900;
}
#planTop{
    /*z-index: 980!important;*/
}

@media (min-width: 960px) {
    #planTop{
        background: #ffffff;
        /*z-index: 999!important;*/
    }
}
@media (max-width: 959px) {
    #home{
        background-size: 100% 100%;
        height: 170vh;
        padding-top: 100px;
    }


    #planTop .uk-navbar-nav > li.uk-active > a, #planTop .uk-navbar-nav > li:hover > a{
        text-transform: none!important;
        font-size: .8rem;
        animation: .5s forwards light-hover-active linear!important;
        animation-fill-mode: forwards;
    }

    .writeUP{
        padding: 15px;
    }

    .writeUP .uk-h1.title {
        font-size: 3.625rem;
        color: #ffffff!important;
    }

    .writeUP .uk-h4 {
        color: #ffffff!important;
    }

    .writeUP>div>div{
        padding: 0px!important;
    }

    .uk-logo{
        padding-left: 15px;
        width: 50vw;
    }
    .uk-sticky-placeholder{
        position: fixed!important;
        height: 0px!important; margin: 0px!important;
    }
    #planTop{
        /*background: linear-gradient(to right, #f9be69, #f99a47);*/
        width: 100vw!important;
    }
    .uk-navbar-nav{
        background:  linear-gradient(to right, #f9be69, #f99a47);
    }
    .uk-navbar-nav>li>a {
        color: #ffffff!important;;
    }
    #planTop .uk-navbar-nav > li > a:before {
        content: "";
        background: #ffffff!important;
        position: absolute;
        width: 100%;
    }
    .uk-navbar-nav>li.uk-active>a{
        color: #c6c6c6;
        font-weight: bold;
    }

}
#planTop nav.uk-navbar-container{
    background: transparent;
    width: 90%;
    margin: 0 auto;
}

#planTop .uk-navbar-nav > li > a{
    text-transform: none!important;
    font-size: .8rem;
}

#planTop .uk-navbar-nav > li.uk-active > a, #planTop .uk-navbar-nav > li:hover > a{
    text-transform: none!important;
    font-size: .8rem;
    animation: .5s forwards hover-active linear;
    animation-fill-mode: forwards;
}

#planTop .uk-navbar-nav > li > a.uk-text-uppercase{
    text-transform: uppercase!important;
    font-weight: bolder!important;
    font-size: 16px;
}

.dark-bg-1{
    background: #18191d!important;
}

.dark-bg-2{
    background: #1c1f24!important;
}

.silver-bg{
    background: silver!important;
}
.gold-bg{
    background: gold!important;
}

.platinum-bg{
    background: #E5E4E2!important;
}

.single canvas{
    /*width: 100%!important;*/
}

.dark-bg-3{
    background: #2a2b30!important;
}

#planTop .uk-navbar-nav > li.uk-active > a.uk-text-uppercase, #planTop .uk-navbar-nav > li:hover > a.uk-text-uppercase{
    text-transform: uppercase!important;
    font-weight: bolder!important;
    font-size: 16px!important;
}

.uk-slidenav polyline {
    stroke-width: 4px;
    color: #ea9e23;
}
@media (max-width: 767px) {
    .bissi>img{
        transform: translateX(100%) rotate(-45deg);
    }
    footer{
        overflow: hidden;
    }

    .bissi .talkto{
        left: 30vh;
    }

    .trst-margin-top{
        margin-top: -1%!important;
    }
}

.uk-slidenav:hover polyline {
    stroke-width: 4px;
    color: #c3990e;
}

header#home >div > ul.uk-slideshow-items{
    height: 80vh!important;
}

header#home > div > ul.uk-slideshow-items >li>div{
    height: 80%!important;
}
@media (max-width: 959px) {

    header#home >div > ul.uk-slideshow-items{
        min-height: 160vh!important;
    }

    header#home >div > ul.uk-slideshow-items>li{
        position: relative!important;
        height: 100%;
    }

    header#home > div > ul.uk-slideshow-items >li>div{
        height: 30%!important;
    }

    .bissi>img{
        visibility: hidden;
    }
}
header#home > div > ul.uk-slideshow-items >li>div>img{
    object-fit: contain;
    object-position: center;
}

@media (min-width: 970px) {
    #home .uk-slidenav-previous{
        left: calc((100vw/5) * 1.65);
        color: #ea9e23;
    }

    #home .uk-slidenav-next{
        right: -20px;
        color: #ea9e23;
    }

}
iframe{
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); /*ios 7 border-radius-bug */
    -webkit-transform: rotate(0.000001deg); /*mac os 10.6 safari 5 border-radius-bug */
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.aboutText .uk-heading-primary{
    color: #ea9e23;
}

/*.aboutText .uk-text-lead{
    font-size: 1rem;
    font-family: 'CW BR Firma', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
}*/

.uk-link.prog-link,.uk-link.prog-link:active,.uk-link.prog-link:visited,.uk-link.prog-link:hover{
    text-decoration: none;
    color: #ea9e23;
}

img.rotateY{
    height: 100px;
    width: auto;
    animation: App-logo-spin 3s linear;
}
#signuplog{
    background: url(../img/regbg.png);
    -webkit-background-size: 100% 115%;
    background-size: 100% 180%;
    margin: 0;
    background-position: center center;
    filter: grayscale(.5);
    height: 100%;
}

.bg-container{
    /*background: url(../img/bgLog.png);*/
    height: 90vh;
    z-index: 5;
    position: relative;
    background-size: 100% 100%;
}

#holder{
    height: 100vh;
}

.uk-input, .uk-select, .uk-textarea{
    border: 2px transparent solid ;
    border-bottom:solid  1px grey ;
    box-shadow: none!important;
    background: transparent;
}

.react-datepicker-wrapper input{
    border: 2px transparent solid ;
    border-bottom:solid  1px #ffffdd;
    color: #ffffff;
    box-shadow: none!important;
    background: transparent;
}

.loginReg h2{
    font-size: 2rem;
    text-align: center;
}

#loginOrg .uk-input, #loginOrg , #loginOrg .uk-textarea{
    border-bottom:solid  1px white ;
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus{
    border: 2px transparent solid ;
    border-bottom: solid 2px #ea9e23 ;
    background: transparent;
}
/*.uk-input::placeholder{
    color: #aaa6!important;
    padding-top: 10px!important;
    font-size: 14px!important;
}
.react-phone-number-input__input::placeholder{
    color: #aaa6!important;
    padding-top: 10px!important;
    font-size: 14px!important;
}
*/
.react-datepicker-wrapper input:focus{
    border: 2px transparent solid ;
    border-bottom: solid 2px #ea9e23 ;
    color: #ffffff;
    background: transparent;
}

#loginOrg .uk-form-label {
    color: #ffffff;
}
.uk-form-label {
    color: grey;
    font-size: .875rem;
    position: absolute;
    top: -15px;
    left: 0;
}

label.uk-form-label.bzsect {
    left: 40px!important;
}

.uk-input:focus ~ label, :focus ~ label, .uk-textarea:focus ~ label, .uk-select:focus ~ label{
    color: #ea9e23!important;
}

#loginOrg .uk-input:focus ~ label, #loginOrg :focus ~ label, #loginOrg .uk-textarea:focus ~ label{
    color: #ADD8E6!important;
}

#loginOrg .uk-input:focus, #loginOrg :focus, #loginOrg .uk-textarea:focus{
    border-bottom: solid 2px #ADD8E6 ;
}

.uk-button{
    /*width: 90%!important;*/
}

.uk-button.prog-button:before{
    content: '';
    left: 0;
    top: 0;
    background: #ea9e23;
    transform: rotate(5deg);
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    border-radius: 5px;
    /*z-index: 0;*/
    transition: all .5s;
}
.uk-button.prog-button{
    /*position: relative;*/
    background: #ea9e23;
    color: #ffffff;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.3rem;
    letter-spacing: 2px;
    border-radius: 5px;
    /*z-index: 1;*/
    transition: all .5s;
}

.uk-button.prog-button:hover:before{
    transform: rotate(-5deg);
    background: #ea9e23;
}

#signup1 .uk-h3, #signup2 .uk-h3{
    color: #ea9e23;
}
label > span.reg-note{
    color: #aaa!important;
    font-style: italic;
    font-size: 10px!important;
}

#loginOrg .uk-h3{
    color: #ffffff;
}

#loginOrg .uk-button.prog-button{
    background-color: #fff;
    color: #e6954e;
}

#bisee{
    height: 60vh;
    right: 70px;
}

#bisee img{
    height: 100%;
}

#loginOrg .uk-button.prog-button:before{
    background-color: #ededed;
    color: #a8860e;
}

a.uk-link-reset.uk-hidden\@m{
    position: absolute;
    bottom: 15px;
    right: 15px;
}

#dashboard .uk-card{
    border-radius: 10px;
    width: 100%!important;
}
#right .uk-card{
    border-radius: 10px;
    width: 100%!important;
}

.uk-card-primary.uk-card-body .uk-input, .uk-card-primary.uk-card-body .uk-textarea, .uk-card-primary>:not([class*=uk-card-media]) .uk-input, .uk-card-primary>:not([class*=uk-card-media]) .uk-textarea, .uk-card-secondary.uk-card-body .uk-input, .uk-card-secondary.uk-card-body .uk-textarea, .uk-card-secondary>:not([class*=uk-card-media]) .uk-input, .uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea, .uk-light .uk-input, .uk-light .uk-textarea, .uk-offcanvas-bar .uk-input, .uk-offcanvas-bar .uk-textarea, .uk-overlay-primary .uk-input, .uk-overlay-primary .uk-textarea, .uk-section-primary:not(.uk-preserve-color) .uk-input,  .uk-section-secondary:not(.uk-preserve-color) .uk-textarea, .uk-tile-primary:not(.uk-preserve-color) .uk-input,  .uk-tile-primary:not(.uk-preserve-color) .uk-textarea, .uk-tile-secondary:not(.uk-preserve-color) .uk-input,  .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea{
    border: none!important;
    border-bottom:solid  1px grey!important;
    box-shadow: none!important;
    background: transparent!important;
}
.uk-card-primary.uk-card-body .uk-input:focus, .uk-card-primary.uk-card-body :focus, .uk-card-primary.uk-card-body .uk-textarea:focus, .uk-card-primary>:not([class*=uk-card-media]) .uk-input:focus, .uk-card-primary>:not([class*=uk-card-media]) :focus, .uk-card-primary>:not([class*=uk-card-media]) .uk-textarea:focus, .uk-card-secondary.uk-card-body .uk-input:focus, .uk-card-secondary.uk-card-body :focus, .uk-card-secondary.uk-card-body .uk-textarea:focus, .uk-card-secondary>:not([class*=uk-card-media]) .uk-input:focus, .uk-card-secondary>:not([class*=uk-card-media]) :focus, .uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea:focus, .uk-light .uk-input:focus, .uk-light .uk-textarea:focus, .uk-offcanvas-bar .uk-input:focus{
    border: none!important;
    border-bottom: solid 2px #ea9e23 !important;
    background: transparent!important;
}

.uk-card-secondary{
    background-color: #222c!important;
}

.bg-container .uk-card-body{
    padding: 0 20px;
}
/* SideNav.css updated by Aef */

/* font awesome icons */

/* open sans font */

/* LTR CSS */
#snav.en {
    left: 0;
    text-align: left;
    top: 30px;
}
#snav.en  li span {
    left: -100px;
}
#snav.en li a:hover span {
    left: 50px;
}

/* RTL CSS */
#snav.ar {
    right: 0;
    text-align: right;
}
#snav.ar  li span {
    right: -100px;
}
#snav.ar li a:hover span {
    right: 35px;
}

/* main SideNav.css styles */
#snav {
    z-index: 9999;
    font-size: 18px;
    font-family: 'CW BR Firma', sans-serif;
    align-self: flex-start;
}
#snav ul {
    list-style: none;
    text-align: center;
    width: 50px;
}
#snav * {
    margin: 0;
    padding: 0;
    outline: 0;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
#snav li a {
    text-decoration: none;
    color: #fff;
    display: block;
    position: relative;
}
#snav svg {
    vertical-align: middle;
    font-size: 18px;
    width: 35px;
    height: 40px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    line-height: 35px;
    text-align: center;
    position: relative;
    z-index: 4;
    object-fit: contain;
}

#bg{
    background: #ffffff;
}

.oview{
    height: 30px;
    width: 40px;
}

#snav li span {
    font-size: 15px;
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    width: auto;
    white-space: nowrap;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    overflow: hidden;
    display: block;
    padding: 0 15px;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: 3;
    background-color: #1d2025!important;
}
#snav li a:hover svg {
    transform: rotate(720deg);
}

#snav li a:hover svg path,.uk-offcanvas-bar li a:hover svg path,#snav li a.uk-active svg path, .uk-offcanvas-bar li a.uk-active svg path{
    fill: #ea9e23;
}

#snav li a:hover svg polyline,.uk-offcanvas-bar li a:hover svg polyline,#snav li a:hover svg rect,.uk-offcanvas-bar li a:hover svg rect,#snav li a.uk-active svg polyline,.uk-offcanvas-bar li a.uk-active svg polyline,#snav li a.uk-active svg rect,.uk-offcanvas-bar li a.uk-active svg rect{
    stroke: #ea9e23;
}

#snav li a:hover span {
    visibility: visible;
    color: #ea9e23;
}

#snav li a:hover,#snav li a.uk-active{
    border-left: 5px solid #ea9e23;
}

.layer1-span{
    height: 40px!important;
    margin-top: 17px!important;
}

.layer1 {
    height: 60px!important;
    width: 40px!important;
}

.tfp-req{
    height: 30px!important;
    margin-top: 13px!important;
}

.uk-offcanvas-bar .tfp-req{
    height: 30px!important;
    margin-top: 13px!important;
}

#off-canvas .span{
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
}

.uk-search-default .uk-search-input {
    border: none!important;
    padding-left: 30px;
}

.uk-search-default .uk-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
}
/* colors styles */

/* menu items title background used for browsers not supporting :nth-child */
#snav li span {
    background-color: #555;
}
/* icons color and background before hover  */
#snav li svg {
    background-color: transparent;
    color: #555
}

/* repeated colors from 1 to 10 each hover color repeated after 10 menu items */
#snav li:nth-child(10n+1) span, #snav li:nth-child(10n+1) a:hover .fa {
    background-color: #8350DD;
}
#snav li:nth-child(10n+2) span, #snav li:nth-child(10n+2) a:hover .fa {
    background-color: #4EC5DB;
}
#snav li:nth-child(10n+3) span, #snav li:nth-child(10n+3) a:hover .fa {
    background-color: #3DC25D;
}
#snav li:nth-child(10n+4) span, #snav li:nth-child(10n+4) a:hover .fa {
    background-color: #99BE24;
}
#snav li:nth-child(10n+5) span, #snav li:nth-child(10n+5) a:hover .fa {
    background-color: #38c;
}
#snav li:nth-child(10n+6) span, #snav li:nth-child(10n+6) a:hover .fa {
    background-color: #ff0000;
}
#snav li:nth-child(10n+7) span, #snav li:nth-child(10n+7) a:hover .fa {
    background-color: #000;
}
#snav li:nth-child(10n+8) span, #snav li:nth-child(10n+8) a:hover .fa {
    background-color: #ea9e23;
}
#snav li:nth-child(10n+9) span, #snav li:nth-child(10n+9) a:hover .fa {
    background-color: #777;
}
#snav li:nth-child(10n+10) span, #snav li:nth-child(10n+10) a:hover .fa {
    background-color: #30555C;
}

/*Solve the overscrolling problem*/
#dashboard  {
    margin-top: 0px!important;
    background-color: #18191d!important;
    position: fixed!important;
    width: 100%!important;
}

.sidelogo{
    width: 35px;
    align-self: flex-start;
    top: 20px;

}

div.uk-sticky.uk-sticky-fixed nav{
    min-height: 50px;
    max-height: 60px;
    background: #000;
    width: 100%;
}

/*.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle{
    min-height: 60px;
    height: 60px;
}*/

#dash-content{
    width: 100%;
    margin-top: -20px!important;
    height: 100vh;
    /*height: 100%;*/
}
@media (max-width: 959px) {
    #dash-content,
    div.uk-sticky.uk-sticky-fixed {
        padding-left: 10px;
    }
    #dashboard div.uk-sticky.uk-sticky-fixed{
        padding-left: 0!important;
    }
}

#left{
    border-right: solid 1px #2a2b30;
    padding-top: 20px;
    width: 75%;
    /*These did the magic of scrolling without distortion*/
    overflow: auto;
    max-height: 100vh;
    padding-bottom: 100px;
}
.dash-margin{
    padding-right: 15px;  
    padding-left: 20px!important;  
}
.uk-dash-margin-left{
    margin-left: -0.3%!important;
}
#right{
    height: fit-content;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 0px;
    width: 25%;
}

.threes{
    min-height: 40vh;
    padding: 0;
    /*padding-right: 15px;*/
}

@media (min-width: 960px) {
    .card-margin-right{
        padding-right: 15px;
    }
}

/*.single{
    padding-top: 40px;
    min-height: 50vh;
    margin-top: 20px;
}*/

.overv{
    background-color: #2a2b30;
    min-height: 93%;
    border-radius: 10px;

}
@media screen and (min-width: 640px) and (max-width: 960px){
    .threes-left{
        width: 100%;
    }
}

@media screen and (min-width: 960px) and (max-width: 1200px){
    .dash-sidebar, .uk-navbar-right{
        display: none!important;
    }
    #left, .right-sidebar{
        margin-top: 5%;
    }
}

.tearn{
    background-color: #2a2b30;
    min-height: 55%;
    border-radius: 10px;
}

.acctype{
    min-height: 65%;
    padding-bottom: 15px;
}
.acctype>div{
    background-color: #1c1f24;
    min-height: 90%;
    position: absolute;
    border-radius: 10px;
}

.acclvl{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #70ca0f;
    margin-top: 35px;
}

.ordinary{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #ea9e23;
    margin-top: 35px;
}

.qualified{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #70ca0f;
    margin-top: 35px;
}

.bonafide{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #54B2E9;
    margin-top: 35px;
}

.matured{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #B60BB0;
    margin-top: 35px;
}
.requested{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #deaa;
    margin-top: 35px;
}

.borrower{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: red;
    margin-top: 35px;
}
.tfpinuse{
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: red;
    margin-top: 35px;
}

.profit{
    min-height: 55%;
    padding-bottom: 15px;
}

.profit>div {
    background-color: #ea9e23;
    min-height: 90%;
    width: 100%;
    position: absolute;
    border-radius: 10px;
}

.tearn-title{
    padding-bottom: 15px;
}

.tearn-title>div {
    min-height: 90px!important;
    background-color: #ea9e23;
    min-height: 90%;
    width: 100%;
    position: absolute;
    border-radius: 10px;
    background-image: url(../img/deposit3.png)!important;
    background-repeat: no-repeat;
}

.border{
    border-right: 0.4mm ridge #ea9e23;
    height: 100px!important;
}

.callcenter{
    min-height: 55%;
    padding-bottom: 15px;
}
.callcenter>div {
    background-color: #ea9e23!important;
}

.amtsvd{
    min-height: 45%;
    border-radius: 10px;
    background-color: #1c1f24;
}

.trstearnings{
    min-height: 45%;
    border-radius: 10px;
    background-color: #43e70e;
}

.t-margin{
    margin-left: 2px!important;
    margin-top: 20%!important; /*This fixed the wrapping on Trust Earnings*/
}

.trst .uk-card-body {
    padding: 5px;
    min-height: 0px!important; /*This fixed the wrapping on Trust Earnings*/
    border-radius: 10px;
}
.trst-title{
    font-size: 150%;
    font-weight: bold;
}
.t-text{
    color: white!important;
    font-size: 50%!important;
}
.uk-text-small{
    font-size: 60%!important;
}
.t-amount{
    padding-top: 5%;
    font-size: 100%;
}
.uk-button-small{
    font-size: 60%!important;
}
.trst .uk-card-body{
    padding: 5px;
    min-height: 100%;
    border-radius: 10px;
}

rect.highcharts-background {
    fill: #1c1f24;
    border-radius: 10px;
}

text.highcharts-title{
    fill: #3f414c!important;
}

.single span , .highcharts-axis-labels text{
    color: #ea9e23 !important;
    fill: #ea9e23 !important;
}

.single{
    padding-right: 20px;
    border-radius: 10px;
}

path.highcharts-grid-line {
    stroke: #393939;
}

#right > div > div{
    background-color: #2a2b30;
    border-radius: 10px;
    height: 100%;
}

#right .uk-heading-primary{
    color: #ea9e23;
    margin-top: 0;
}

.incprof{
    margin-bottom: 20px;
}

.text-normal{
    font-weight: 100;
}

.uk-text-huge{
    font-size: 2rem;
}

.uk-text-larger{
    font-size: 1.9rem;
    margin-top: -15px;
}

.tearn .uk-text-larger-red{
    font-size: 1.6rem;
    color: red!important;
}
.tearn .uk-text-larger-theme{
    font-size: 1.6rem;
    color: #ea9e23!important;
}
.tearn .uk-text-larger-green{
    font-size: 1.6rem;
    color: #70ca0f!important;
}

.acctype .uk-text-larger{
    color: #ffffff;
}

.learn{
    align-self: flex-end;
}

.learn .uk-link,.learn .uk-link:hover,.learn .uk-link:visited{
    text-decoration: none!important;
}

/*Circle Chart Css*/
#jquery-script-menu {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    border-top: 5px solid #316594;
    background: transparent;
    -moz-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    z-index: 999999;
    padding: 10px 0;
    -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;
}

.jquery-script-center {
    width: 960px;
    margin: 0 auto;
}
.jquery-script-center ul {
    width: 212px;
    float:left;
    line-height:45px;
    margin:0;
    padding:0;
    list-style:none;
}
.jquery-script-center a {
    text-decoration:none;
}
.jquery-script-ads {
    width: 728px;
    height:90px;
    float:right;
}
.jquery-script-clear {
    clear:both;
    height:0;
}

.cir{
    background-color: rgb(28, 31, 36) !important;
    top: 5px !important;
    left: 5px !important;
    width: 90px !important;
    height: 90px !important;
}

span.perc{
    color: #ffffff!important;
}

.ab{
    background-color: #34332c!important;
}
/*Circle Chart Css*/

.uk-button.button-out{
    background: transparent;
    color: #ea9e23;
    padding: 0 10px;
    margin-right: 10px;
    width: auto;
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 2px;
    border-radius: 5px;
    /*z-index: 1;*/
    /*transition: all .5s;*/
    border: solid 1px #ea9e23;
}
.uk-button.button-redeem{
    background: transparent;
    color: #fff;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 2px;
    border-radius: 5px;
    /*z-index: 1;*/
    transition: all .5s;
    border: solid 2px #fff;
}
.uk-button.dash-button{
    background: #ea9e23;
    color: #ffffff;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 2px;
    border-radius: 5px;
    transition: all .5s;
}
.higher-index{
    z-index: 9999!important;
}

.uk-button.dash-button2{
    background: #70ca0f;
    color: #ffffff;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 2px;
    border-radius: 10px;
    /*z-index: 1*/
    transition: all .5s;
}
.uk-button.dash-button3{
    background: #ccdcba;
    color: #ffffff;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 2px;
    border-radius: 5px;
    /*z-index: 1;*/
    transition: all .5s;
}


.uk-button.button-out:hover{
    color: #ea9e23;
    border: none;
}
.uk-button.button-redeem:hover{
    color: #ea9e23;
    border: solid 2px #ea9e23;
}

.uk-button.dash-button:hover{
    background-color: #ea9e23;
}

.acctype .uk-card-body{
    padding: 20px;
}

.theme-color,.theme-color path, .theme-color circle, .theme-color polygon{
    color: #ea9e23!important;
    stroke: #ea9e23!important;
    fill: #ea9e23!important;
}
/*SIDEBAR ICON COLOR*/
.theme-color .fa{
    color: #ea9e23!important;
}
.red-color .fa{
    color: red!important;
}

.uk-badge{
    background-color: #ea9e23!important;
}

.tfp-color{
    color: #70ca0f!important;
}
.tfp-color-inuse{
    color: red!important;
}
.tfp-profile-status{
    margin-top:5px!important;
}

.theme-bg{
    background-color: #ea9e23!important;
}

.overv .uk-text-small{
    color: grey;
}

.overv .uk-text-large ~ .uk-text-small{
    font-size: .56rem;
}

.currency{
    position: absolute;
    color: grey;
    font-size: .5rem;
    top: -5%;
}
.lowertext{
    font-size: 10px;
}
.uk-search-default .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
    padding-left: 40px;
    border: none;
    border-radius: 10px;
}

.uk-search .uk-search-icon:not(a):not(button):not(input) {
    pointer-events: none;
    color: white;
}

.uk-search{
    background: #2a2b30;;
    border-radius: 10px;
    color: white;
}

.uk-search-input{
    color: #ffffff;
}

.userimg{
    border-radius: 100;
    width: 35px;
    height: 35px;
    overflow: hidden;
}

.br50{
    border-radius: 100!important;
}

.update-img{
    border-radius: 100;
    width: 105px;
    height: 105px;
}

.nav-toggle{
    width: 35px;
    height: 35px;
    object-fit: contain;
    transform: rotate(180deg);
}
/*.uk-select{*/
/*background: #2a2b30;;*/
/*border-radius: 10px;*/
/*border: none;*/
/*}*/
.nav-dropdown{
    background: #2a2b30;;
    border-radius: 10px;
    border: none;
    height: 40px;
    padding-left: 10px;
}

.nav-card{
    background-color: #2a2b30!important;
    padding: 0 0 0 0;
}

.nav-dropdown span.uk-margin-small-right.uk-icon{
    color: #ffffff;
}

#usr-det .uk-dropdown{
    background: #2a2b30;
}

#cust{
    background: #ea9e23;
    border-radius: 10px;
}
.cust-con{
    margin: 30px 0px -35px 0px;
    color: #fff;
}
.tnm{
    font-style: italic;
    color: grey;
}

#notif .uk-link{
    color: #ffffff;
}

.uk-light{
    border: none!important;
}


#bgWrite .uk-text-huge{
    color: #ffffff;
}

.uk-button-white{
    background-color: #fff;
    color: #000;
}

.uk-button-white:hover{
    color: #b7950b;
}
@media (min-width: 960px) {

    #register-view{
        margin: 0 auto;
        /*max-height: 85vh;*/
        min-height: 90vh;
        overflow: hidden;
        width: 80vw;
        box-shadow: 0 5px 20px #c4c4c4;
        font-family: "CW BR Firma", sans-serif;
    }

    .forgereset #register-view{
        margin: 0 auto;
        height: 80vh;
        overflow: hidden;
        width: 50vw;
        box-shadow: 0 5px 20px #c4c4c4;
    }
}


#signin{
    overflow: hidden!important;
}
@media (max-width: 959px) {
    #signin{
        min-height: 80vh;
    }
    #bgWrite{
        min-height: 100vh;
    }

    #register-view{
        margin: 0 auto;
        min-height: 90vh;
        /*overflow: hidden;*/
        width: 70vw;
        box-shadow: 0 5px 20px #c4c4c4;
    }
}

#bg{
    background: #ea9e23;
    min-height: 100vh;
    background-size: 100% 100%;
}
#register-view{
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.48);
}

#bgWrite{
    background: url(../img/regbg.png);
    background-size: cover;
    background-position: left bottom;
}

::-webkit-scrollbar
{
    width: 7px;  /* for vertical scrollbars */
    height: 7px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
    background: #ffffff;
}

::-webkit-scrollbar-thumb
{
    background: #ea9e23;
    border-radius: 55px;
}

.trusteesList::-webkit-scrollbar
{
    position: relative;
    width: 17px;  /* for vertical scrollbars */
    height: 17px; /* for horizontal scrollbars */
}

.trusteesList::-webkit-scrollbar:before{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
}

.trusteesList::-webkit-scrollbar-track
{
    background: transparent;
}

.trusteesList::-webkit-scrollbar-thumb
{
    background: #858585;
    border-radius: 55px;
}

.scrollerline:after{
    content: '';
    position: absolute;
    top: 0;
    right: 48.5px;
    height: 100%;
    width: 1px;
    background: #858585;
}

@media (max-width: 1199px) {
    .scrollerline:after{
        content: '';
        right: 38.5px;
    }
}

@media (max-width: 500px) {
    .t-margin {
        margin-left: 2px!important;
        margin-top: 28%!important;
    }
}

.bottom-right{
    position: absolute;
    bottom: 50px;
    right: 50px;
}

#bgWrite .uk-text-large{
    color: #ffffff;
    line-height: 1.2;
    font-size: 2.1rem;
}
@media (max-width: 959px) {
    .padding-right{
        padding-right: 20px;
    }

    #register-view{
        margin: 0 auto;
        min-height: 90vh;
        /*overflow: hidden;*/
        width: 100vw;
        background: url(../img/mobileBG.png);
        background-size: 100% 100%  ;
    }

    .padding-left{
        padding-left: 20px;
    }
}

button.uk-link{
    background: transparent;
    border: solid 0;
}
#links{
    position: absolute;
    bottom: 50px;
    min-width: 90%;
}

#links > a{
    padding-right: 10px;
    padding-left: 10px;
    border-right: solid 1px #c4c4c4;
}

#links > a:last-of-type{
    padding-right: 10px;
    padding-left: 10px;
    border-right: none;
}

#links > a.uk-button{
    padding-right: 10px;
    padding-left: 10px;
    border-right: none;
    color: #000;
}

#bg .uk-alert{
    position: absolute;
    transform: translateY(-100%);
    top: 0;
}

.tfp-notification{
    background: #18191d;
}

.pop{
    border: 2px solid #1c1f24!important;
    color: #1c1f24!important;
}

.dark-bg-1{
    background: #18191d!important;
}

.dark-bg-2{
    background: #1c1f24!important;
}

.single canvas{
    /*width: 100%!important;*/
}

.dark-bg-3{
    background: #2a2b30!important;
}

.pop[disabled]{
    background: #898989;
}

.pop:hover{
    border: 2px solid #181818 !important;
    color: #181818!important;
}

.pop:visited{
    border: 2px solid #181818 !important;
    color: #181818!important;
}

.uk-offcanvas-bar{
    background: #1c1f24;
    overflow-x: hidden;
}

div .uk-sticky .uk-sticky-fixed .uk-sticky-placeholder {
    height: 0px !important;
}
.payLoan .uk-countdown-number {
    font-size: 2rem!important;
}

.logoHolder{
    padding-right: 30px;
}

.logoHolder img{
    height: 68px;
    width: auto;
}

.payLoan .uk-countdown-number .uk-countdown-separator {
    font-size: 1.7rem;
}

.payLoan .uk-countdown-label {
    font-size: .8rem!important;
}

.amtsvd>svg{
    width: 32%!important;
    height: 32%!important;
}

.plan .uk-background-secondary{
    background: rgba(0, 0, 0, 0.94) !important;
    border-radius: 10px;
}

.planImg{
    border-radius: 10px;
    box-shadow: -1px 1px 20px #bfbdbd85;
}

.uk-list>li{
    text-align: left
}

.back-btn{
    position: absolute;
    bottom: 40px;
    left: 0;
}

.silver .uk-list>li:before{
    background-image: url(../img/ICONS/SILVER.png);
}
.gold .uk-list>li:before{
    background-image: url(../img/ICONS/GOLD.png);
}
.platinum .uk-list>li:before{
    background-image: url(../img/ICONS/PLATINUM.png);
}

span#not-num {
    position: absolute;
    top: -10px;
    left: 10px;
}
.plan .button-out{
    border-color: silver;
}

.gold .button-out{
    border-color: gold;
}

.plan .button-out{
    color: silver;
}
.gold .button-out{
    color: gold;
}

.plan .uk-list>li:before{
    width: 19px;
    content: "";
    position: absolute;
    left: -25px;
    top: 5px;
    height:20px;
    -webkit-background-size: contain;
    background-size: contain;
}

#plan-list{
    padding-left: 50px;
}

.features{
    font-size: 1.6rem;
    color: #737373;
}

.plan .uk-text-huge{
    color: #ea9e23!important;
}


#plan {
    background: url(../img/contact.png);
    -webkit-background-size: 100% 115%;
    background-size: 100% 180%;
    margin: 0;
    background-position: center center;
    filter: grayscale(.5);
    position: fixed!important;
}

.plan>div{
    padding-bottom: 70px    ;
}

.plan{
    padding-bottom: 100px;
}

#planView{
    background: #000;
    position: fixed;
}
.silvbtn{
    color: silver!important;
    border: solid 2px silver!important;
}

.silvbtn:hover{
    color: #9f9f9f !important;
    border: solid 2px #9f9f9f !important;
}

.silvlnk{
    color: silver;
    text-decoration: none!important;
}
.silvlnk:hover{
    color: #858585;
    text-decoration: none!important;
}

.platbtn{
    color: #adadad!important;
    border: solid 2px #adadad!important;
}


.platbtn:hover{
    color: #858585!important;
    border: solid 2px #858585!important;
}

.platlnk{
    color: #adadad;
    text-decoration: none!important;
}
.platlnk:hover{
    color: #7d7d7d;
    text-decoration: none!important;
}


.goldlnk{
    color: gold;
    text-decoration: none!important;
}
.goldlnk:hover{
    color: #d1ae00;
    text-decoration: none!important;
}

.plansview{
    padding: 100px 20px;
}

.plansview>div{
    padding-bottom: 300px;
}

.planhover{
    -webkit-transition: all 2s; /* For Safari 3.1 to 6.0 */
    transition: all 2s ease;
    text-align: center;
}

.planhover:hover .rotateY{
    transform: rotateY(360deg);
    -webkit-transition: all 2s; /* For Safari 3.1 to 6.0 */
    transition: all 2s ease;
}

/*Smiley*/
@keyframes smiley-blink {
    0% {
        margin-top: 36%;
        height: 2%;
        border-radius: 25%;
    }
    1%, 100% {
        margin-top: 30%;
        height: 16%;
        border-radius: 100;
    }
}

@keyframes smiley-look-to-sides-left-eye {
    0% {
        margin-left: 27%;
    }
    0.5%, 5% {
        margin-left: 17%;
    }
    6%, 9% {
        margin-left: 30%;
    }
    9.5%, 100% {
        margin-left: 27%;
    }
}

@keyframes smiley-look-to-sides-right-eye {
    0% {
        margin-right: 27%;
    }
    0.5%, 5% {
        margin-right: 37%;
    }
    6%, 9% {
        margin-right: 24%;
    }
    9.5%, 100% {
        margin-right: 27%;
    }
}

.message-smiley{
    min-width: 50vw;
}

.delaypnt5s{
    animation-delay: 500ms;
}

.uk-padding-medium{
    padding: 30px;
}
.smiley {
    position: relative;
    min-width: 40vh;
    height: 40vh;
    border-radius: 100;
    top: 50%;
    /*box-shadow: 0px 0px 20px;*/
}

.somE{
    position: absolute;
    opacity: .1;
    width: 20vh;
    height: 20vh;
}

.text-cont{
    min-width: 70vh;
}
@media (max-width: 959px) {
    .text-cont{
        min-width: 70vw!important;
    }
}
.leftSome{
    left: 111px;
    top: 120px;
}

.downsome{
    bottom: 90px;
    left: 250px;
}
.somesome{
    top: 50px;
    left: 50%;
}

.smiley .smiley-eye {
    position: relative;
    width: 10%;
    height: 16%;
    background-color: #292949;
    margin-top: 30%;
    border-radius: 100;
}

/*.smiley .smiley-eye {*/
/*position: relative;*/
/*width: 16%;*/
/*height: 16%;*/
/*background-color: #fff;*/
/*margin-top: 30%;*/
/*border-radius: 100;*/
/*}*/

/*.smiley .smiley-eye:before {
    content: " ";
    position: absolute;
    top: 40%;
    left: 50%;
    width: 30%;
    height: 30%;
    border-radius: 100;
    background-color: #38ae37;
} */

.smiley .smiley-left-eye {
    float: left;
    margin-left: 27%;
}

.smiley .smiley-left-eye-down {
    float: left;
    margin-left: 27%;
}

.smiley.happy-state .smiley-left-eye {
    animation: smiley-blink 10s ease-in 2s infinite normal, smiley-look-to-sides-left-eye 10s linear 0s infinite normal;
}

.smiley .smiley-right-eye-down {
    float: right;
    margin-right: 27%;
}

.smiley .smiley-right-eye {
    float: right;
    margin-right: 27%;
}

.smiley.happy-state .smiley-right-eye {
    animation: smiley-look-to-sides-right-eye 10s linear 0s infinite normal;
}

.smiley.blink .smiley-left-eye {
    height: 2%;
    margin-top: 36%;
    border-radius: 25%;
}

.planwrite{
    color: silver;
    text-align: center;
}

.plnhead{
    color: #878787;
    font-size: 2rem;
}

.smiley.happy-state .smiley-mouth {
    position: absolute;
    background-color: #fff;
    width: 50%;
    height: 20%;
    bottom: 22%;
    left: 50%;
    margin-left: -25%;
    border-radius: 0 0 1000px 1000px;
}

.smiley.sad-state .smiley-mouth {
    position: absolute;
    background-color: #000000;
    width: 50%;
    height: 20%;
    bottom: 24%;
    left: 50%;
    margin-left: -25%;
    border-radius: 1000px 1000px 0 0;
}

/*.smiley.sad-state .smiley-mouth {*/
/*position: absolute;*/
/*background-color: #fff;*/
/*width: 50%;*/
/*height: 20%;*/
/*bottom: 24%;*/
/*left: 50%;*/
/*margin-left: -25%;*/
/*border-radius: 1000px 1000px 0 0;*/
/*}*/

.smiley.sad-state .smiley-mouth-clear {
    position: absolute;
    background-color: #ffffff;
    width: 48%;
    height: 20%;
    bottom: 24%;
    top: 58%;
    left: 51%;
    margin-left: -25%;
    border-radius: 1000px 1000px 0 0;
}

@keyframes smiley-cry {
    0% {
        width: 1%;
        height: 1%;
        opacity: 0;
        top: 45%;
        right: 78%;
        transform: rotate(-20deg);
    }
    2% {
        opacity: 1;
        bottom: 45%;
    }
    10% {
        width: 10%;
        height: 10%;
    }
    15% {
        transform: rotate(-45deg);
        top: 50%;
    }
    25% {
        opacity: 1;
    }
    32% {
        top: 95%;
        opacity: 0;
    }
    45% {
        top: 95%;
        opacity: 0;
    }
    50%, 100% {
        top: 100%;
        opacity: 0;
    }
}

.smiley .smiley-tear {
    position: absolute;
    top: 45%;
    right: 78%;
    width: 10%;
    height: 10%;
    background-color: skyblue;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    animation: smiley-cry 4s ease-in 0s infinite normal;
}


.smiley.happy-state {
    background: url(../img/emoticons/success.png);
    background-size: 100% 100%;
}

.message-smiley.uk-grid-collapse.uk-flex-middle.uk-flex.uk-first-column{
    width: fit-content;
}

.smiley.sad-state {
    background: url(../img/emoticons/error.png);
    background-size: 100% 100%;
}

.rotateInLeft {
    -webkit-animation-name: rotateInLeft;
    animation-name: rotateInLeft;
    animation-duration: 1s;
    animation-fill-mode: both;
}
@media (max-width: 959px) {
    .wrap\@s{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .smiley {
        position: relative;
        width: 30vh;
        height: 30vh;
        border-radius: 100;
    }
}

#error{
    background-color: #ffffff;
    z-index: 23;
}

#success{
    z-index: 23
}

@keyframes rotateInLeft{
    0% {
        top: 0;
        -webkit-transform-origin: left;
        transform-origin: left;
        left: -150px;
        -webkit-transform: rotate3d(0, 0, 1, -900deg);
        transform: rotate3d(0, 0, 1, -900deg);
        opacity: 0;
    }
    100% {
        top: 0;
        left: 0;
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.slideInLeft{
    animation-name: slidInLeft;
}

@keyframes slidInLeft {

    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: hidden;
    }

    100% {
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
/*Smiley*/


#confetti {
    position: relative;
    top:0;
    left: 0;
    z-index: 1;
    width: 100%;
}

@media (min-width: 576px){
    .uk-container {
        max-width: 540px;
    }
}

@media (min-width: 768px){
    .uk-container {
        max-width: 720px;
    }
}
@media (min-width: 992px){
    .uk-container {
        max-width: 960px;
    }
}
@media (min-width: 1200px){
    .uk-container {
        max-width: 80%;
    }
}

.uk-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.newPlan .uk-card-media-top{
    padding: 5px 5px 0;
}
.newPlan .uk-card{
    border-radius: 10px;
}
@media (min-width: 960px) {
    .plan-container{
        bottom: -10vh;
    }
}

.newPlan button{
    padding: 10px 50px;
    border-radius: 5px;
    border: none;
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    font-size: 1.4rem;
}

.newPlan{
    text-align: center;
}

.silver-card button{
    background: silver;
    transition: background .3s;
}

.silver-card button:hover{
    background: #858585;
    transition: background .3s;
}

.gold-card button{
    background: gold;
    transition: background .3s;
}

.gold-card button:hover{
    background: #bf9800;
    transition: background .3s;
}

button{
    cursor: pointer;
}

.platinum-card button{
    background: #bebbb6;
    transition: background .3s;
}

.platinum-card button:hover{
    background: #928f8c;
    transition: background .3s;
}

.silver-card h3, .silver-card p{
    color: silver;
}

.gold-card h3, .gold-card p{
    color: gold;
}

.platinum-card h3, .platinum-card p{
    color: #bebbb6;
}

.col-white>*, .col-white{
    color: #ffffff;
}

@media (max-width: 959px) {
    .uk-grid-large>* {
        padding-left: 20px;
    }
    .img-holder> img{
        display: none;
    }

    .img-holder{
        position: absolute;
        background: url(../img/pple.png) no-repeat center;
        opacity: .2;
        height: 100%;
        background-size: auto 100%;
    }
}
.planRest ul{
    list-style-type: none;
}

.planRest ul>li:before{
    content: "-";
    font-size: 2rem;
    position: absolute;
    line-height: 1rem;
    left: -1rem;
    top: 1.2px;
}

.silver-card li{
    color: silver;
}

.platinum-card li{
    color: #bebbb6;
}

.gold-card li{
    color: gold;
}

.planRest button{
    padding: 10px 50px;
    border-radius: 5px;
    border: none;
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    font-size: 1.4rem;
}

.planRest button a{
    color: #ffffff;
}
.uk-sticky.uk-sticky-below.uk-sticky-fixed{
    left: 0!important;
}

.uk-logo{
    height: 60px;
}

div.uk-sticky.uk-sticky-fixed  .uk-logo{
    height: 40px;
}
nav{
    /*min-height: 100px;*/
    display: flex;
    align-items: center;
}

/*.uk-navbar-nav>li>a{
    text-transform: none!important;
    font-weight: 400;
    color: #ededed!important;
    font-family: 'CW BR Firma', sans-serif;
}*/
div.uk-sticky.uk-sticky-fixed, #planTop div.uk-sticky.uk-sticky-fixed {
    width: 100% !important;
    padding-left: 60px;
    right: 10px;
    min-height: 60px;
    background: #FED40C;
    color: #ffffff;
}

/*.uk-navbar-nav>li.uk-active>a,.uk-navbar-nav>li:hover>a, .uk-navbar-nav>li>a.uk-open, .uk-navbar-nav>li>a:focus{
    color: #ffffff!important;
    font-weight: 600;
}*/

.uk-logo>img{
    height: 100%;
}

body{
    max-width: 100vw;
}

.toggleImg {
    width: auto;
    height: 30px;
    object-fit: contain;
}

.planRest{
    padding: 100px 20px;
}

#bg{
    min-height: 100vh;
    background:#ea9e23;
    display: flex;
    align-items: center;
}

.border-rounded{
    border-radius: 10px;
}

#signin .logo{
    position: absolute;
    top: 0;
    right: 30px;
}

@media (max-width: 959px) {
    #signin .logo{
        position: absolute;
        top: 0;
        right: 15px;
    }
}
/*#success {*/
/*position: absolute;*/
/*background: rgba(255,255,255,1);*/
/*box-shadow: 10px 10px 10px rgba(255,255,255,0.8);*/
/*padding: 20px;*/
/*margin: 0 auto;*/
/*height: 200px;*/
/*margin-top: -100px;*/
/*top: 50%;*/
/*left: 0;*/
/*right: 0;*/
/*bottom:0;*/
/*text-align: center;*/
/*width: 400px;*/
/*z-index: 2;*/
/*color: gray;*/
/*}*/
.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 9999;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

img.modal-img {
    width: 70%;
}

.terms ul{
    line-height: 30px;
}

.requirementCard img{
    height: 100px;
    margin-right: 10px;
}

.requirementCard span{
    color: #d4d4d4;
}

.requirementCard span.uk-margin-right{
    margin-right: 5px!important;
}


.uk-button-circle{
    border-radius: 5px;
    border: 2px solid gold;
    height: 50px;
    width: 50px;
    padding: 0;
    line-height: 45px;
}

.plus{
    border: 2px solid #298036!important;
    color: #ffffff!important;
}
.tfp-notification .plus,.tfp-notification .minus{
    font-size: .6rem!important;
}

.ReactTable .rt-thead.-header{
    color: #ffffff;
    text-shadow: 0 0 20px #ffd800;
}

.loan.text-normal{
    font-size: 1.5rem;
}

.uk-card-secondary .uk-card-footer{
    background: linear-gradient( #202021 10%, #0e0e0e 90%);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.minus{
    border: 2px solid red!important;
    color: #ffffff!important;
}

.minus:hover{
    background: red;
    border: 2px solid red;
    color: #ffffff!important;
}

.plus:hover{
    background: #298036;
    border: 2px solid #298036;
    color: #ffffff!important;
}

.uk-button-circle:hover{
    border-radius: 100;
    background: gold;
    color: #000000!important;
    border: 2px solid gold
}

#planView .moveit{
    background: #000;
}

.toggleImg {
    width: auto;
    height: 30px;
    object-fit: contain;
}

#planSet, div.uk-sticky.uk-sticky-fixed,.uk-sticky-placeholder{
    background: #000000!important;
}

#planSets,.planPlace div.uk-sticky.uk-sticky-fixed,.planPlace .uk-sticky-placeholder{
    background: #bcaa57!important;
    color: #000;
}
#planSet .uk-navbar-center, #planSets .uk-navbar-center{
    top: 0!important;
    transform: none!important;
}

#planSets .uk-navbar-nav>li>a, #planSets .uk-navbar-nav>li>a{
    color: #000;
}

@media (max-width: 959px){
    .uk-navbar-nav {
        flex-direction: column;
        top: 60px;
        width: 100%;
        position: fixed;
        background-color: #000000;
        left: 0;
    }

    #planSets .uk-navbar-nav {
        flex-direction: column;
        top: 60px;
        width: 100%;
        position: fixed;
        background-color: #bcaa57;
        left: 0;
    }
}
.padding-bottom{
    padding-bottom: 70px;
}

.moveit{
    position: relative;
    margin-bottom: 150px;
    z-index: 9999999999;
}

.uk-card-secondary>:not([class*=uk-card-media]) :focus,.uk-button-circle:active,.uk-button-circle:focus{
    border-radius: 100!important;
    background: #b69800!important;
    color: #000000!important;
    border: 2px solid gold!important;
}

.mtb-20{
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}

.prog-button[disabled]{
    transition: none!important;
    transform: none!important;
    background: grey!important;
}

.prog-button[disabled]:before{
    content: none!important;
    transition: none!important;
    transform: none!important;
    background: grey!important;
}

#parentDiv{
    min-width: 98vw;
}

.authLinks>a:before{
    content: "";
    background: #ea9e23;
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 15px;
    padding-bottom: 2px;
    left: 0;
    border-radius: 5px;
}

.uk-text-bold{
    font-family: "CW BR Firma", sans-serif;
    font-weight: bold;
}

.authLinks>a.uk-active{
    color: #ea9e23!important;
}
.authLinks>a,.authLinks>a:hover.authLinks>a:active.authLinks>a:visited{
    color: grey!important;
    font-weight: bold;
    margin: 40px 0;
    font-size: 1.5rem;
    height: 60px;
    text-decoration: none!important;
}



.plan{
    padding-bottom: 400px;
}
.uk-dotnav {
    align-items: center;
}

.uk-dotnav>.uk-active>* {
    background-color: rgb(231, 188, 14);
    border: none;
    width: 13px;
    height: 13px;
}
.uk-dotnav>*>* {
    background: rgba(238, 191, 14, 0.8);
    border: none;
}

.bycont{
    color: #d4d4d4;
    font-weight: 600;
    font-family: 'CW BR Firma', sans-serif;
}
.bycont a{
    color: rgb(231, 188, 14);
    font-weight: 600;
    text-decoration: none!important;
}

.casenone{
    text-transform: none!important;
}

#dash-content{
    padding-left: 0;
}

@media (min-width: 959px) {
    #register-view.trustee{
        margin: 0 auto;
        max-height: 80vh;
        height: 80vh;
        overflow: hidden;
        width: 50vw;
        padding: 0 50px;
        box-shadow: 0 5px 20px #808080;
    }
}

@media (max-width: 1279px) {
    .visible-default{
        display: flex;
    }
}

#dashboard div.uk-sticky.uk-sticky-fixed {
    width: 100%!important;
    padding-left: 0;
    right: 0!important;
    padding-right: 10px;
    max-width: 100vw;
    left: 0;
}

@media (min-width: 1360px) {
    #dash-content {
        width: 100%;
    }

    .visible-default{
        display: none;
    }

    #dash-sidebar.uk-offcanvas {
        display: block;
    }
    #dash-sidebar .uk-offcanvas-bar {
        width: 240px;
        transform: translateX(0);
        padding: 40px 40px;
    }
    #dashboard div.uk-sticky.uk-sticky-fixed {
        width: 100%!important;
        /*padding-left: 265px;*/
    }

    .hidden-default{
        display: none;
    }
}

#dash-sidebar .uk-offcanvas-bar{
    padding: 20px 0;
}

.dash-sidebar>li{
    position: relative;
    padding-left: 0;
    display: flex;
    align-items: center;
    transition: all .3s;
}

.dash-sidebar.uk-nav-primary>li>a{
    position: static;
    transition: all .3s;
}

.dash-sidebar.uk-nav-primary>li>.uk-active:before, .dash-sidebar.uk-nav-primary>li:hover>a:before{
    content: '';
    border-left: solid #ea9e23 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #2A2B30;
    transition: all .3s;
}
.dash-sidebar.uk-nav-primary>li>span>svg{
    padding-left: 40px;
    border-left: solid transparent 5px;
    box-sizing: content-box;
}

.canvas-logo{
    height: 50px;
}

.dash-sidebar.uk-nav-primary>li>a {
    font-size: 1rem;
    line-height: 35px;
    color: #999;
    display: inline-block;
    padding-left: 20px;
    transition: all .3s;
}

/*html, body{
    max-width: 100vw;
    max-height: 100vh;
    overflow-x: hidden;
}*/

.dash-sidebar.uk-nav-primary>li:hover>a,.dash-sidebar.uk-nav-primary> li> .uk-active>span{
    color: #ea9e23;
    transition: all .3s;
}

.right-sidebar{
    position: fixed;
    width: auto;
    overflow: auto;
    max-height: 100vh;
    padding-right: 20px;
    padding-bottom: 100px;
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #ea9e23!important;
}

.dash-sidebar::-webkit-scrollbar {
    width: 1px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.dash-sidebar::-webkit-scrollbar-thumb {
    background: #ea9e23!important;
}


svg{
    width: 20px;
    height: 20px;
    transition: all .3s;
}

svg .a, .dash-sidebar svg path {
    fill: #858585;
    transition: all .3s;
}
.uk-active ~ span > svg .a ,.dash-sidebar.uk-nav-primary>li:hover .a, .dash-sidebar.uk-nav-primary>li:hover path, .uk-active ~ span > svg path {
    fill: #ea9e23;
    transition: all .3s;
}

.uk-search-default{
    background: transparent!important;
    padding-left: 50px;
}

.loanCard{
    background: #2A2B30;
}

.uk-card.border-none{
    border-radius: 0;
}

.text-alert{
    color:red;
}

.uk-table-divider>:first-child>tr:not(:first-child), .uk-table-divider>:not(:first-child)>tr, .uk-table-divider>tr:not(:first-child) {
    border-top: 1px solid #1d1313
}

a, a:hover, a:active, a:visited, a:focus{
    text-decoration: none!important;
}

.trusteeTable a:not(:last-of-type){
    margin-right: 20px;
}

.trusteeTable a{
    color: #858585;
}

.trusteeTable a.uk-active, .trusteeTable a:hover{
    color: #ea9e23;
}

.pad-right{
    padding-right: 20px;
}

.uk-table-hover tbody tr:hover, .uk-table-hover>tr:hover {
    background: #000000;
}

.uk-checkbox, .uk-radio {
    display: inline-block;
    height: 16px;
    width: 16px;
    overflow: hidden;
    margin-top: -4px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #858585 no-repeat 50% 50%;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in-out;
    transition-property: background-color,border;
}

.uk-checkbox:checked, .uk-checkbox:active {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
}
/*.uk-checkbox:checked, .uk-checkbox:active {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
}*/
.contribution .current{
    font-size: 3rem;
}
.contribution .current:before{
    right: -15px;
}

.contribution .preve:before {
    right: -15px;
}
.preve{
    /*font-size: 3rem;*/
}

.preve:before {
    position: absolute;
    content: 'NGN';
    color: #858585;
    font-size: .6rem;
    top: -3px;
}

.addred span{
    font-size: 3rem;
}

.contribution .uk-button.button-out, .setup .uk-button.button-out {
    background: transparent;
    color: #858585;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.5rem;
    letter-spacing: 2px;
    border-radius: 5px;
    z-index: 1;
    transition: all .5s;
    border: none;
}

a.uk-button.uk-active.button-out {
    background: transparent;
    color: #ea9e23;
    padding: 0 15px;
    width: auto;
    text-transform: none;
    font-size: 1.5rem;
    letter-spacing: 2px;
    border-radius: 5px;
    z-index: 1;
    transition: all .5s;
    border: solid 2px #ea9e23;
}

a.uk-button.uk-active.button-out:hover {
    color: #ea9e23;
    border: solid 2px #ea9e23;
}

.addTrustee{
    height: 120px;
}

.trusteesList{
    height: calc((100vh) - 60px);
}

.trusteesList .uk-card-default .uk-card-footer {
    border-top: 1px solid transparent;
    background: #2A2B30;
}

.lmore{
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.uk-button:not(.prog-button, .help-button, .button-out){
    padding-left: 30px!important;
}

.setupLeft{
    positive: relative;
    margin-top: 60px;
}

.uk-card{
    overflow: hidden;
}

.setupRight{
    margin-top: 45px;
}

.setupLeft:before{
    content: '';
    position: absolute;
    border-right: solid 1px #2A2B30;
    width: 100%;
    height: 55vh;
    top: 50%;
    right: 0;
    transform: translateY(-55%);
}

.avatar{
    height: 250px;
    width: 250px;
    object-fit: cover;
    object-position: center -15px;
    image-orientation: from-image;
}

.text-dk2{
    color: #5D5D5D;
}

.uk-table th{
    font-weight: bold;
}

.help-button{
    margin-bottom: -65px;
}

#dash-content label{
    color: #5D5D5D;
}

.uk-card-dark-2{
    background: #1c1f24;
    border-radius: 10px;
}

.trusteeCOnfirm{
    background: rgba(24, 25, 29, 0.75) !important;
}

.loanComplete{
    background: rgba(24, 25, 29, 0.75) !important;
}

.trusteeCOnfirm .uk-card{
    overflow: visible;
    padding-top: 60px;
}

.trusteeCOnfirm .uk-icon{
    position: absolute;
    top: 30px;
    right: 30px;
}

.loanComplete .uk-icon{
    position: absolute;
    top: 30px;
    right: 30px;
}

.trusteeCOnfirm .uk-icon svg{
    width: 40px;
    height: 40px;
}

.loanComplete .uk-icon svg{
    width: 40px;
    height: 40px;
}

.trusteeAD{
    background: rgba(24, 25, 29, 0.75) !important;
}

.trusteeAD .uk-card{
    overflow: visible;
    padding-top: 60px;
}

.trusteeAD .uk-icon{
    position: absolute;
    top: 30px;
    right: 30px;
}

.trusteeAD .uk-icon svg{
    width: 40px;
    height: 40px;
}

.light{
    margin: 0;
    font-weight: lighter;
}

.text-dk1{
    color: #a4a4a4;
}

.trusteeAD .uk-icon svg path, .trusteeCOnfirm .uk-icon svg path, .loanComplete .uk-icon svg path{
    color: #ffffff;
}

.uk-border-rounded {
    border-radius: 10px!important;
}

.trusteeCOnfirm img{
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
}

.trusteeAD img{
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
}

.w-100{
    width: 100%!important;
}

.z-indHigh{
    z-index: 9999;
}

.lineheight{
    line-height: 1.5;
}

.card-footer{
    margin-top: 15px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
}

.card-footer .uk-button{
    line-height: 60px;
}

.trustbtn{
    background: #199E00;
    color: #ffffff;
}

.declinbtn{
    background: #9E0000;
    color: #ffffff;
}

.trustbtn:hover{
    background: #198400;
    color: #ffffff;
}

.declinbtn:hover{
    background: #7a0000;
    color: #ffffff;
}

.w-50{
    width: 50%!important;
}

.uk-text-vsmall{
    font-size: .7rem;
}

.helpCard {
    overflow: visible;
}
#planTop div.uk-sticky.uk-sticky-fixed, #planTop .uk-sticky-placeholder {
    background: #FED40C!important;
}

.uk-card img{
    object-fit: contain;
}

.border-none{
    border: none!important;
}

.cursor-p{
    cursor: pointer;
}

.pb-small{
    padding-bottom: 20px;
}

.br-b{
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

@media (max-width: 1359px) {
    .uk-hidden\@m.uk-navbar-item{
        display: flex!important;
    }
}

#register-view .uk-text-small {
    font-size: .875rem;
    line-height: 1.5;
    font-weight: bolder;
    letter-spacing: 1px;
}

.logoHolder a{
    display: inline-block;
    width: 300px;

    height: 60px;
}/*Add Card*/

.uk-input.full{
    background: #808080;
    color: #000;
}

input::placeholder {
    color: #fff !important;
}

.br-50 {
    border-radius: 100;
}

.button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none!important;
}

/*Dashboard Retouch*/
.threes{
    display: flex;
    flex-direction: column;
}

.account-type{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-basis: 70%;
    overflow: hidden;
    border-radius: 10px;
}

.account-level{
    flex-basis: 30%;
    padding-top: 20px;
}

.account-type .uk-button.button-out{
    font-size: .6rem;
}

.account-level div{
    display:  flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
}

.profit-card{
    flex-basis: calc(((100%) - 20px)/2);
    margin-bottom: 20px;
}

.profit-card div{
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
}

.amount-saved{
    flex-basis: calc(((100%) - 20px)/2);
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
    display:  flex;
    align-items: center;
    padding: 25px;
}


.learn{
    display: flex;
    align-items: flex-end;
}

.profit-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bnksel{
    /* left: -40px; */
}
.uk-text-warning svg path{
    stroke: #faa05a!important;
}
.uk-text-warning:hover svg path{
    stroke: #d2864c !important;
}
.uk-text-danger *{
    stroke: #f0506e!important;
}
.uk-text-danger:hover *{
    stroke: #db4964 !important;
}

.trustee p, .trustee label{
    color: dimgrey;
    font-size: 1.2rem;
    font-weight: 600;
}

.text-dk2.uk-link-reset.uk-text-small{
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.pointnone ul>li>a{
    pointer-events: none!important;
    cursor: text;
}

/*Dashboard Retouch*/
.uk-tab > .uk-active > a {
    color: #333;
    border-color: #ea9e23!important;
}

.dash-sidebar {
    position: fixed;
    display: inherit;
    width: 205px;
    overflow-y: scroll;
    bottom: 0px;
    top: 5%;
}

/*.dash-sidebar ul{
    margin-top: 110%;
}*/

li.nav-list>a{
    font-size: 14px;
    padding: 5px;
    color: #858585;
    border-bottom: 1px solid #505565;
    font-family: 'CW BR Firma', sans-serif;
    width: 100%;
}
li.nav-list>a>i{
    width: 18%;
}
li.nav-list>a>svg{
    margin-right: 5%;
}
.nav-list:hover{
    background-color: #ea9e2359;
}
ul.uk-nav-mobile{
margin-top: 25%;
}
li.nav-list-mobile{
    list-style-type: none;
    margin-bottom: 3%!important;
}
li.nav-list-mobile>a{
    font-size: 18px;
    margin-left: -10%;
    color: #858585;
}
.nav-list-mobile:hover{
    color: #ea9e23!important;
}
.m-nav-tab{
    margin-left: 5%!important;
}
/*SVG FILL*/
svg .a, .nav-list-mobile svg path {
    fill: #858585;
    transition: all .3s;
}

#tfp-card{
    margin-left: 30px;
    background: #292a2f;
    border-radius: 5px;
}
#name-card{
    padding: 10px;
    background: #ea9e23;
    border-radius: 5px;
}
.tfp-title{
    padding: 5px;
    margin-top: -5px;
    font-size: 15px;
}
.uk-card-loan{
    background-color: #292a2f;
}
.req{
    background-color: #aaa3;
    padding: 5px;
    border-radius: 15px;
}

/*VALIDATION CLASSES*/
.uk-form-controls span{
    color: red!important;
    font-size: 14px;
}

input.uk-checkbox{
    /*background: #ea9e23!important;*/
    background: #fff!important;
    border-color: #ea9e23!important;
    border-width: 1px;
    border: ridge;
}

label a{
    color: #ea9e23!important;
}

label span{
    margin-left: 5px!important;
    color: red!important;
}
/*END VALIDATION CLASSES*/

.terms-modal{
  position: absolute!important;
  overflow:scroll!important;
  width: 800px!important;
  height: 630px!important;
}

.val-w {
  margin-top: -20px!important;
  margin-bottom: -90px!important;
}
.val-f{
  padding-top: -50px!important;
}
.t-partner{
    margin-top: 5px;
    font-size: 20px!important;
    border: none;
    background-color: #ea9e23!important;
    width: 100%;
    padding-left: 20px!important;
    padding-top: 5px!important;
    padding-bottom: 5px!important;
    color: #000!important;
}
.loader {
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after, .loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 10px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.tn-remove{
    position: relative;
    float: right;
    margin-top: -45px;
    margin-right: -20px;
}

#scroller * {
    /* don't allow the children of the scrollable element to be selected as an anchor node */
    overflow-anchor: none;
}

#anchor {
    /* allow the final child to be selected as an anchor node */
    overflow-anchor: auto;

    /* anchor nodes are required to have non-zero area */
    height: 30px;
    margin-top: -30px;
}

@media (min-width: 1200px) {
    .trans-margin{
    margin-top: -380px!important;
    }
}

.filter{
    border: ridge 1px;
}

.link:hover{
    color: #ea9e23!important;
}
.text-red{
    color: red!important;
}
.text-purple{
    color: #B60BB0!important;
}
.text-blue{
    color: #54B2E9!important;
}
.text-green{
    color: green!important;
}
.text-white{
    color: white!important;
}
.text-grey{
    color: grey!important;
}
.text-process{
    color: #deaa!important;
}
.trans-text{
    font-size: 12px!important;
}
.up-text{
    font-size: 200%!important;
}
.divider{
    border-bottom: ridge 1px #ea9e23;
    width: 94%;
    margin-top: 10px;
}
.uk-tab > .uk-active > a {
    color: #ea9e23!important;
    border-color: #ea9e23!important;
}

/*ul>li>a:hover{
    color: #ea9e23!important;
}*/
.link{
    color: grey!important;
    font-size: 16px!important;
}

ul>li.uk-active > * {
  /*color: #ea9e23!important;*/
}

ul>li > * {
    font-family: 'CW BR Firma', sans-serif!important;
}

td{
  color: grey!important;
}
.uk-form-label-dash{
    font-size: 15px!important;
    color: #ea9e23!important;
}

.item-right{
    float: right;
    margin-bottom: -20px;
    margin-right: -20px;
}

.uk-dropdown-nav{
    margin-left: -20px!important;
    line-height: 30px;
    margin-bottom: -10px;
    margin-top: -20px;
    font-size: 5px;
}
.name{
    font-size: 15px!important;
}
.uk-dropdown-nav > li > a {
    color: #999;
    font-size: 12px;
    list-style: none;
}
.uk-dropdown-nav.acc>li{
    border-bottom: ridge 1px;
    border-color: #ea9e23!important;
    margin-left: -25px;
    margin-right: -15px;
    margin-right: -15px;
}
.max-limit{
 color:red;
 font-size:15px;
}
.ReactModal__Overlay--after-open{
    background-color:#1e2227e3!important;
}

label{
    font-size: 18px!important;
}
.margin-left{
    margin-left: 100px!important;
}
.field>span{
    color: red!important;
}
.field-data span{
    color: red!important;
    display: block;
}
.fa{
    color: #858585!important;
}
.nav-dropdown-transparent{
    background-color: transparent!important;
}

.float{
    position:fixed;
    width:100px;
    height:auto;
    bottom:40px;
    right:40px;
    /*background-color:#0C9;*/
    /*color:#FFF;
    border-radius:50px;*/
    /*text-align:center;*/
    /*box-shadow: 2px 2px 3px #999;*/
}

.my-float{
    margin-top:22px;
}

.react-datepicker{
    background-color: #1e2227e3!important;
}
.react-datepicker__current-month{
    color: #ea9e23!important;
}
.react-datepicker__triangle {
    color: #000!important;
}
.react-datepicker__month-container .react-datepicker__header {
    background-color: #292a2f!important;
}

.react-datepicker__day-name{
    color: #fff!important;
}
.react-datepicker__day{
    color: #fff!important;
}
.react-datepicker__day--outside-month{
    color: #fff!important;
    opacity: 0.3;
    color: red!important;
}
.react-datepicker__day:hover{
    background-color: #ea9e2370!important;
}
.react-datepicker__day--keyboard-selected{
    background-color: #ea9e23!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
    background-color: #ea9e23!important;
}
.react-datepicker-popper {
    width: 100px!important;
    z-index: 99999 !important;
}
.react-datepicker__day--disabled{
    opacity: 0.3!important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__current-month, .react-datepicker__month-container{
    margin: 0px!important;
    padding: 0px!important;
    font-size: 10px!important;
}

.border{
    border-right: 1px ridge;
    border-color: #ea9e23!important;
}
.uk-input{
    color: #666666!important;
}
.input-width.uk-input{
    width: 80%!important;
}

.set-error-msg span{
    color: red!important;
    font-size: 14px;
    margin-top: -25px;
}
.agreement{
    font-size: 14px!important;
}

.user-photo:hover{
    opacity: 0.3!important;
}
.photo-icon{
    margin-top: 10px;
    width:40px!important;
    height: 2%!important;
}
.photo-frame{
    border-radius: 100%!important;
    width: 150px!important;
    height: 150px!important;
}

.trend-title{
    position: fixed!important;
    z-index: 1;
    background-color: #18191d;
    padding-left:4%;
    padding-right:4%;
    padding-bottom:1%;
}
.mkt-pos{
    background-color: green!important;
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin: 3px;
    display: inline;
}
.mkt-title{
    font-size: 14px;
}
.mrkt-user{
    font-size: 12px!important;
    color: #9E9A99!important;
}
.mrkt-comment::placeholder{
    font-size: 12px!important;
    color: grey!important;
    font-style: italic;
}
.complete{
    font-size: 12px!important;
    text-decoration: line-through!important;
    font-style: italic;
    display: inline!important;
    color: grey!important;
}
.not-complete{
    font-size: 12px!important;
    text-decoration: line-through;
    font-style: italic;
    display: inline!important;
    color: red!important;
}
.completed{
    font-size: 12px!important;
    font-style: italic;
    display: inline!important;
    color: #ea9e23!important;
}
.icon-small{
    width: 12px!important;
}
.icon-padding{
    margin-right: 5px!important;
}

.notifications-text{
    font-size: 14px!important;
}

.notifications-text .uk-accordion-title{
    font-size: 14px!important;
}
.notifications-text .uk-accordion-content{
    padding-right: 10px;
}
.profile-text{
    font-size: 12px!important;
}

.chat-area{
    overflow-y: scroll;
    overflow-x: none;
    /*position: fixed;*/
    height: 45vh!important;
}
.chat-input{
    display: relative;
    position: fixed;
    bottom: 5px;
    width: 61vw!important;
    height: 7vh!important;
}
.chat-msg{
    /*font-size: 10px!important;*/
    padding: 1px!important;
    padding-right: 10px!important;
    padding-left: 10px!important;
    margin: 1px!important;
}
.chat-time{
    font-size: 8px!important;
    margin: 2px!important;
}
.chat-img{
    margin: 2px!important;
}
.chat-text{
    font-size: 14px!important;
}
.w-icon{
    width: 38px!important;
    height: 38px!important;
}

.w-icon-m{
    width: 30px!important;
    height: 30px!important;
    /* display: inline-flex; */
    /* margin-right: -20!important; */
    float: right!important;
}

.pci-note{
    font-size: 14px!important;
    margin-top: 20px;
    background: none!important;
    font-style: italic;
}

.react-datepicker-wrapper {
    display: inline!important;
}

.react-datepicker__input-container {
    display: inline!important;
}

input.date-field{
    display: inline!important;
}

caption > div{
    display: inline!important;
}   
caption > div > div{
    display: inline!important;
}
.react-phone-number-input__input, .react-phone-number-input__phone{
    background: none;
    color: grey;
}
.react-phone-number-input__input, .react-phone-number-input__phone:hover{
    border-color: #ea9e23!important;
}
.uk-card-title{
    line-height: 20px;
}
.user-chat-header{
    position: fixed;
}
.theme-background{
    background-color: #ea9e23!important;
    border-radius: 10px;
}
.txt-h{
    padding-top: 2%;
    line-height: 25px!important;
    font-size: 160%;
}
.plan-img{
    width: 90%!important;
    /*height: 35px;*/
}
#dashboard .uk-card{overflow: visible;}

.react-phone-number-input__icon{
    border:none!important;
}
.react-phone-number-input__icon-image{
    margin-top: -12px!important;
}
.footer-text{
    color: #c1b8b8ad!important;
    height: 5%!important;
    top: 5%!important;
    bottom: 0!important;
    font-size:13px;
    padding:5px;
}
.footer-text a{
    color: #ea9e23!important;
    text-decoration: none;
}
.threes .uk-grid > * {
    padding-left: 15px!important;
}
.plan-grid {
    padding-left: 25px!important;
}
.uk-text-small {
    font-size: 75%;
    line-height: 1.2;
}
.uk-grid > * > :last-child {
    margin-bottom: 20px!important;
}
.hide{
    display: none!important;
}
.set-top{
    margin-top: -20px!important;
}
.react-phone-number-input{
    margin-top: 10px!important;
}
.error{
    background-image: url(../img/errort.png)!important;
    background-repeat: no-repeat;
}
.text-width{
    font-size: 14px;
    width: 30px!important;
    word-break: break-all!important;
}
.t-link{
    color:grey!important;
    font-size: 14px!important;
    background: none!important;
    border: none!important;
    word-break: break-all!important;
    font-style: italic;
    width: 100%!important;
    align-content: center;
    text-align: center;
}
.sidebar-margin-top{
    margin-top: 40%!important;
}

@-moz-document url-prefix(){
    .t-margin{
        margin-left: 2px!important;
        margin-top: 20%!important; /*This fixed the wrapping on Trust Earnings for mozilla*/
    }
}
.dash-logo{
    width: 90%;
}
img.dash-icon{
    /*right: 1%;*/
    width: 80%;
    margin-top: 10%;
    margin-bottom: -20%;
    margin-left: 2%;
}
.chat-type-width{
    width: 82%;
}
.small-text{
    font-size: 80%;
}

.swal-overlay--show-modal{
    /* background-color:#1e2227e3!important; */
    z-index: 9999999!important;
}
/*.swal-title, .swal-text{
    color: white!important;
}
*/
.swal-button{
    background-color: #ea9e23!important;
}
.swal-button-container{
    background-color: none!important;
}